import { colors } from 'assets/styles/colors';
import { Label } from 'assets/styles/globalStyles';
import { FontSizes } from 'utils/enums';
import Button from 'antd/es/button';
import styled from "styled-components";

interface StyledComponentProps {
  marginTop?: number;
  marginBottom?: number;
  backgroundColor?: string;
  width?: string;
}

export const StyledButton = styled(Button) <StyledComponentProps>`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  border-radius: 6px;
  font-size: ${FontSizes.button}rem;
  padding: 8px 15px;
  height: 35px;
  color: white;
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : colors.primary};
  width: ${({ width }) => width || 'auto'} !important;
  transition:0.4s;
  box-shadow:none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background-color: ${({ backgroundColor }) => backgroundColor || colors.primaryDark} !important;
  }
  .ant-spin-dot-item{
    background-color: #ffffff !important;
  }

  ${Label} {
    display: inline-block !important;
    cursor: pointer;
  }

  .ant-tag {
    margin: 0;
    margin-left: 2px;
    font-size: ${FontSizes.error}rem;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;