
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from 'lodash';
import {
    AdvertiseWrapper,
    EventListWrapper,
    FilterWrapper,
    MyEventItem,
    SearchTextWrapper,
    SearchWrapper
} from "./styles";
import Button from "components/Button";
import { Checkbox, Collapse, DatePicker } from 'antd';
import { CalendarOutlined, CheckOutlined, EnvironmentOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import Loader from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
type CheckboxValueType = string[];
const CheckboxGroup = Checkbox.Group;

const Search: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userDetails, searchText } = useSelector((store: any) => store.commonData);
    const [searchParams] = useSearchParams();
    const [searchTextUrl, setSearchTextUrl] = useState<string>(searchParams.get('searchText') || '');
    const urlParams = new URLSearchParams(window.location.search);
    const categoryParam = urlParams.get('category');
    const categoryValue = categoryParam ? decodeURIComponent(categoryParam) : '';
    const [regions, setRegions] = useState<any[]>([]);
    const [checkedRegionList, setCheckedRegionList] = useState<CheckboxValueType>([]);
    const [checkedCategoryList, setCheckedCategoryList] = useState<CheckboxValueType>([]);
    const [date, setDateRange] = useState<any>('');
    const [categories, setCategories] = useState<any[]>([]);
    const [events, setEvents] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' });


    const fetchRegions = async () => {
        let payload = {
            "endpoint": "/list_region",
            "data": {}
        };
        const res = await fetchData(restAPIs.commonAPI(payload));
        let tempList = res?.data?.regions.map((region: any) => ({ value: region?.id, label: region?.itemName }));
        setRegions(tempList);
    };

    const fetchCategories = async () => {
        let payload = {
            "endpoint": "/list_category",
            "data": {}
        };
        const res = await fetchData(restAPIs.commonAPI(payload));
        let tempList = res?.data?.eventCategories.map((category: any) => ({ value: category?.id, label: category?.title }));
        setCategories(tempList);
    };

    const fetchEvents = async () => {
        let payload: any = {
            "endpoint": "/searchEvents",
            "data": {
                searchKey: searchText,
                filters: {
                    category: checkedCategoryList,
                    region: checkedRegionList,
                    dateRange: {
                        startDate: date[0],
                        endDate: date[1]
                    }
                },
                page: 1,
                limit: 10
            }
        };

        if (userDetails?.data?.user_id) {
            payload.data.user_id = userDetails.data.user_id;
        }

        setIsLoading(true);
        const res = await fetchData(restAPIs.commonAPI(payload));
        setEvents(res?.data?.results);
        setIsLoading(false);
    };

    const debouncedFetchEvents = useCallback(debounce(fetchEvents, 500), [searchText, checkedRegionList, checkedCategoryList]);

    useEffect(() => {
        fetchRegions();
        fetchCategories();
    }, []);

    useEffect(() => {
        if (searchText !== searchTextUrl) {
            dispatch({
                type: "searchText",
                value: searchTextUrl,
            });
        }
    }, [searchTextUrl]);

    useEffect(() => {
        debouncedFetchEvents();
    }, [searchText, checkedRegionList, checkedCategoryList]);

    useEffect(() => {
        if (categoryValue) {
            const selectedCategories = categories.filter((category: { label: string; }) => category.label === categoryValue);
            setCheckedCategoryList(selectedCategories.map((category: { value: any; }) => category.value));
        }
    }, [categoryValue, categories]);

    const onChangeRegion = (list: CheckboxValueType) => {
        setCheckedRegionList(list);
    };

    const onChangeCategory = (list: CheckboxValueType) => {
        setCheckedCategoryList(list);
    };

    const formatDateRange = (startDate: string, endDate: string): string => {
        const [startYear, startMonth, startDay] = (startDate?.split('-') || []).map(Number);
        const [endYear, endMonth, endDay] = (endDate?.split('-') || []).map(Number);

        if (!startYear || !startMonth || !startDay || !endYear || !endMonth || !endDay) {
            throw new Error("Invalid date format");
        }

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthName = monthNames[startMonth - 1];

        return `${startDay} - ${endDay} ${monthName} ${startYear}`;
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTextUrl(e.target.value);
    };

    const handleEventClick = (eventId: any) => {
        navigate('/event/' + eventId);
    };

    const handleSubmit = () => {
        fetchEvents();
    };

    const onDateChange = (dates: any, dateStrings: any) => {
        setDateRange(dateStrings);
    };

    return (
        <>
            <SearchWrapper>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                           {!isTabletOrMobile && <FilterWrapper>
                                {(checkedRegionList.length > 0 || checkedCategoryList.length > 0 || date.length > 0) && <div className="__filter_btn">
                                    <Button label="Apply" type="dashed" icon={<FilterOutlined />} onClickHandler={() => handleSubmit()} />
                                </div>}
                                <Collapse defaultActiveKey={['1', '2', '3']} ghost>
                                    <Panel header={<span style={{ fontWeight: '700' }}>Calendar</span>} key={'1'}>
                                        <RangePicker onChange={onDateChange} />
                                    </Panel>
                                    <Panel header={<span style={{ fontWeight: '700' }}>Region</span>} key={'2'}>
                                        <CheckboxGroup options={regions} value={checkedRegionList} onChange={onChangeRegion} />
                                    </Panel>
                                    <Panel header={<span style={{ fontWeight: '700' }}>Category</span>} key={'3'}>
                                        <CheckboxGroup options={categories} value={checkedCategoryList} onChange={onChangeCategory} />
                                    </Panel>
                                </Collapse>
                            </FilterWrapper>}

                           { isTabletOrMobile && 
                            <FilterWrapper>
                                <Collapse ghost>
                                <Panel header={<span style={{ fontWeight: '700' }}>Filter</span>} key={'a1'}>
                                {(checkedRegionList.length > 0 || checkedCategoryList.length > 0 || date.length > 0) && <div className="__filter_btn">
                                    <Button label="Apply" type="dashed" icon={<FilterOutlined />} onClickHandler={() => handleSubmit()} />
                                </div>}
                                <Collapse defaultActiveKey={['1', '2', '3']} ghost>
                                    <Panel header={<span style={{ fontWeight: '700' }}>Calendar</span>} key={'1'}>
                                        <RangePicker onChange={onDateChange} />
                                    </Panel>
                                    <Panel header={<span style={{ fontWeight: '700' }}>Region</span>} key={'2'}>
                                        <CheckboxGroup options={regions} value={checkedRegionList} onChange={onChangeRegion} />
                                    </Panel>
                                    <Panel header={<span style={{ fontWeight: '700' }}>Category</span>} key={'3'}>
                                        <CheckboxGroup options={categories} value={checkedCategoryList} onChange={onChangeCategory} />
                                    </Panel>
                                </Collapse>
                                </Panel>
                            </Collapse>
                            </FilterWrapper>
                            
                            
                            }

                        </div>
                        <div className="col-md-6 mb-4">
                            <EventListWrapper>
                                {events && events.map((item: any, idx: any) => (
                                    <MyEventItem key={idx} onClick={() => handleEventClick(item?.id)}>
                                        <div className="___date">
                                            <img src={item?.eventLogo} alt="" />
                                            {item?.role && item?.role !== '' &&
                                                <div className="__ticker" style={{ background: userDetails?.data?.userType === 'sponsor' ? '#8913ff' : userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00' }}><CheckOutlined /></div>
                                            }
                                        </div>
                                        <div className="__left">
                                            <h1>{item?.title}</h1>
                                            <p>{item?.desc}</p>
                                            <span><EnvironmentOutlined /> {item?.location}</span>
                                            <span><CalendarOutlined /> {formatDateRange(item?.startDate, item?.endDate)}</span>
                                        </div>
                                    </MyEventItem>
                                ))}

                                {isLoading && <Loader />}

                                {events && events.length === 0 && 'No Results found.!'}
                            </EventListWrapper>
                        </div>
                        <div className="col-md-3 mb-4">
                            <AdvertiseWrapper>
                                {/* Advertisement space */}
                            </AdvertiseWrapper>
                        </div>
                    </div>
                </div>
            </SearchWrapper>
        </>
    );
};

export default Search;
