
import React, { useEffect, useState } from 'react';

import InputField from 'components/InputField';
import Textarea from 'components/TextArea';
import Button from 'components/Button';
import type { GetProps, GetProp, UploadProps } from 'antd';
import { Upload, DatePicker, Radio, Collapse, Switch, message, notification } from 'antd';

import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonSec, CommonFieldWrap, EventJoinWrap, JoinAs, SelectedCard, SponCardItem, TableSection } from './styles';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

import Slider from "react-slick";

import EventCover from 'assets/images/eventCover.png'
import { useNavigate, useParams } from 'react-router-dom';
import CustomSelect from 'components/Select';
import PhoneInput from 'react-phone-input-2';
import Loader from 'components/Loader';


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const { Panel } = Collapse

interface RouteParams {
    eventId: string;
}
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const EventJoin: React.FC = () => {

    const [formData, setFormData] = useState<any>({});
    const [formErrors, setFormErrors] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = notification.useNotification();
    const [messageApiWarn, contextHolderMessage] = message.useMessage();
    const [isSwiping, setIsSwiping] = useState(false);
    const [selectedCar, setSelectedCard] = useState<any>('')
    const [selectedRole, setSelectedRole] = useState<any>();
    const { eventId } = useParams();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [eventImg, setEventImg] = useState<any>('');
    const [formFieldLoading, setFormFieldLoading] = useState(false)
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState<any>([])

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: !isSwiping,
        initialSlide: currentSlide,
        beforeChange: () => setIsSwiping(true),
        afterChange: () => setIsSwiping(false),
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleChange = (name: any, value: any) => {
        setFormData({ ...formData, [name]: value });
        if (name === 'sponsorship') {
            const options = formFields.find((role: any) => role.roleInfo.roleId === selectedRole)?.fieldInfo.find((field: any) => field.name === 'sponsorship')?.options;
            setSelectedCard(options?.find((sponsorship: any) => sponsorship.value === value));
        }
        // Clear error if value is provided
        if (value && formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: null });
        }
    };

    const handleFileChange = (e: any, name: any) => {
        const file = e.file;
        setFormData({
            ...formData,
            [name]: file,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // Validate form fields
        const errors: any = {};
        formFields.forEach((role: any) => {
            if (role.roleInfo.roleId === selectedRole) {
                role.fieldInfo.forEach((field: any) => {
                    if (field.required && !formData[field.name]) {
                        errors[field.name] = `${field.label} is required`;
                    }
                });
            }
        });

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true)
            let formD = new FormData();
            formD.append('endpoint', "/regUserFeedback");
            formD.append('role', selectedRole)
            if (eventId)
                formD.append('eventId', eventId);
            Object.keys(formData).forEach(key => {
                formD.append(key, formData[key]);
            });
            const res = await fetchData(restAPIs.commonAPI(formD));
            setLoading(false)
            if (res?.status === 'SUCCESS') {
                openNotificationWithIcon('success', 'Success', res?.message)
                setSelectedCard('')
                setFormData({});
                setFormErrors({})
                setCurrentSlide(0);
            }
            else {
                openNotificationWithIcon('error', 'Error', res?.message)
            }


        }
    };

    const handleCardClick = (value: any, name: any) => {
        if (!isSwiping) {
            setSelectedCard(value)
            setFormData({ ...formData, [name]: value });
        }
    }

    const removeSelectedCard = () => {
        setSelectedCard('')
        setFormData({
            ...formData,
            ['sponsorship']: '',
        });
    }

    const fetchSponsorships = async () => {
        setFormFieldLoading(true)
        let payload = {
            "endpoint": "/getCampaignForm",
            "data": {
                eventId: eventId
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        if (res?.status === 'SUCCESS') {
            setFormFields(res?.data?.formInfo)
            setEventImg(res?.data?.eventImg)
            setSelectedRole(res?.data?.formInfo[0]?.roleInfo.roleId);
            setFormFieldLoading(false)
            setFormData({ ['countryCode']: '+971' });

        }
        else {
            openNotificationWithIcon('error', 'Error', res?.message)
            setFormFieldLoading(false)
            navigate('/event/' + eventId)
        }

    };


    useEffect(() => {
        fetchSponsorships();
    }, [])



    const handleRoleChange = (e: any) => {
        setSelectedRole(e.target.value);
        setSelectedCard('')
        setFormData({});
        setFormData({ ['countryCode']: '+971' });
        setFormErrors({})
        setCurrentSlide(0);

    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };


    const handleChangePhone = (value: any, country: any, e: any, formattedValue: any) => {
        setFormData({ ...formData, ['countryCode']: '+' + formattedValue });
    }

    const handleKeyDown = (event: any) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }

    return (
        <>
            <EventJoinWrap>
                {contextHolder}
                {formFieldLoading ? (
                    <Loader fullscreen size='large' />
                ) : (
                    <>
                        <JoinAs>
                            <label><strong>Join as</strong></label>
                            <Radio.Group optionType="button" onChange={handleRoleChange} value={selectedRole} defaultValue={selectedRole} buttonStyle={'outline'} style={{ backgroundColor: 'transparent' }}>
                                {formFields && formFields?.map((role: any) => (
                                    <Radio key={role.roleInfo.roleId} value={role.roleInfo.roleId}>
                                        {role.roleInfo.title}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </JoinAs>

                        {formFields.some((role: any) => role.roleInfo.roleId === selectedRole && role.fieldInfo.some((field: any) => field.name === 'sponsorship')) && (
                            <TableSection>
                                <Collapse accordion defaultActiveKey={['1']} ghost>
                                    <Panel header={<span style={{ fontWeight: '700' }}>Sponsors Benefits Chart</span>} key={'1'}>
                                        <div className='__spon_card_list'>
                                            <Slider {...settings}>
                                                {formFields.map(
                                                    (role: any) =>
                                                        role.roleInfo.roleId === selectedRole && (
                                                            role.fieldInfo.map((field: any, index: any) =>
                                                                field.name === 'sponsorship' && (
                                                                    field.options.map((item: any, idx: any) => (
                                                                        <div key={idx} onClick={() => handleCardClick(item, 'sponsorship')}>
                                                                            <SponCardItem style={{ background: item?.value === selectedCar?.value ? '#ff8a004f' : '' }}>
                                                                                <div className='___head'>
                                                                                    <a style={{ backgroundColor: item?.color }}>{item?.label}</a>
                                                                                    <h2>{item?.cost}</h2>
                                                                                </div>
                                                                                {item?.deliverables && <div className='__body'>
                                                                                    {item?.deliverables && item?.deliverables.map((delItem: any, id1: any) => (
                                                                                        <div key={id1}>
                                                                                            <h5>{delItem?.section}</h5>
                                                                                            {delItem?.benefits.map((benItem: any, id2: any) => (
                                                                                                <p key={id2}>{benItem}</p>
                                                                                            ))}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>}
                                                                            </SponCardItem>
                                                                        </div>
                                                                    ))
                                                                )
                                                            )
                                                        )
                                                )}
                                            </Slider>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </TableSection>
                        )}

                        {selectedCar && <SelectedCard>
                            <div>
                                <p>Benefit Selected</p>
                                <h2>{selectedCar?.label}</h2>
                                <h1>{selectedCar?.cost}</h1>
                            </div>
                            <CloseCircleOutlined onClick={() => removeSelectedCard()} />

                        </SelectedCard>}
                        <form style={{ marginTop: '40px' }}>
                            <div className="steps-content">
                                {formFields.map(
                                    (role: any) =>
                                        role.roleInfo.roleId === selectedRole && (
                                            role.fieldInfo.map((field: any, index: any) => (
                                                <>
                                                    {field.type === 'select' && (
                                                        <CustomSelect
                                                            value={formData[field.name] || ""}
                                                            onChangeHandler={(value: any) => handleChange(field.name, value)}
                                                            label={field.label}
                                                            options={field.options.map((option: any) => ({
                                                                label: option.label,
                                                                value: option.value
                                                            }))}
                                                            error={formErrors[field.name]}
                                                            isMandatory={field.required}
                                                        />
                                                    )}
                                                    {field.type === 'textarea' && (
                                                        <Textarea
                                                            value={formData[field.name] || ""}
                                                            onChange={(value: any) => handleChange(field.name, value)}
                                                            label={field.label}
                                                            error={formErrors[field.name]}
                                                            isMandatory={field.required}
                                                        />
                                                    )}
                                                    {field.type === 'text' && (
                                                        <InputField
                                                            value={formData[field.name] || ""}
                                                            onChange={(value) => handleChange(field.name, value)}
                                                            label={field.label}
                                                            error={formErrors[field.name]}
                                                            isMandatory={field.required} color={''} />
                                                    )}
                                                    {field.type === 'email' && (
                                                        <InputField
                                                            type='email'
                                                            value={formData[field.name] || ""}
                                                            onChange={(value) => handleChange(field.name, value)}
                                                            label={field.label}
                                                            error={formErrors[field.name]}
                                                            isMandatory={field.required} color={''} />
                                                    )}

                                                    {field.type === 'tel' && field.name === 'phone' && (
                                                        <div className='__forMob'>
                                                            <div className='__country'>
                                                                <CommonFieldWrap>
                                                                    <label>{'Country Code'}</label>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <PhoneInput
                                                                            placeholder=''
                                                                            country={'ae'}
                                                                            inputClass='__inp'
                                                                            value={formData["countryCode"] || ""}
                                                                            onChange={handleChangePhone}
                                                                            inputProps={{
                                                                                name: 'countryCode',
                                                                                required: field.required,
                                                                            }}
                                                                            disableCountryCode={true}
                                                                            inputStyle={{ display: 'none' }}
                                                                        />
                                                                        <span className='__name_cls'>{formData["countryCode"]}</span>
                                                                    </div>
                                                                </CommonFieldWrap>

                                                            </div>
                                                            <InputField
                                                                type='phone'
                                                                onKeyDown={(e) => handleKeyDown(e)}
                                                                value={formData[field.name] || ""}
                                                                onChange={(value) => handleChange(field.name, value)}
                                                                label={field.label}
                                                                error={formErrors[field.name]}
                                                                isMandatory={field.required} color={''} />
                                                        </div>
                                                    )}

                                                    {field.type === 'file' && (
                                                        <CommonFieldWrap >
                                                            <label><strong>{field.label}</strong></label>
                                                            <Upload
                                                                listType="text"
                                                                onChange={(e) => handleFileChange(e, field.name)}
                                                                beforeUpload={() => false}
                                                                accept={field.accept}
                                                                maxCount={1}
                                                            >
                                                                {uploadButton}
                                                            </Upload>
                                                        </CommonFieldWrap>
                                                    )}

                                                    {field.type === 'switch' && (
                                                        <CommonFieldWrap >
                                                            <label><strong>{field.label}</strong></label>
                                                            <Switch defaultChecked onChange={(value) => handleChange(field.name, value)} checkedChildren="Yes" unCheckedChildren="No" value={formData[field.name] || ""} />
                                                        </CommonFieldWrap>
                                                    )}

                                                </>
                                            ))
                                        ))}
                            </div>
                            <ButtonSec>
                                <Button type="primary" label='Submit' onClickHandler={handleSubmit} loading={loading} width='120px' />
                            </ButtonSec>

                        </form>

                        {eventImg && <div style={{ margin: '30px 0' }}>
                            <img src={eventImg} alt="" style={{ width: '100%', height: '100px', objectFit: 'contain' }} />
                        </div>}
                    </>)}
            </EventJoinWrap>
        </>
    );
};

export default EventJoin;
