
import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const ModalWrap = styled.div`
    width:100%;

    .__phoneInp{
        display: flex;
        align-items: baseline;

        .__inp {
            width: 100px !important;
            margin-right: 10px;
            opacity: 1;
            height: 40px;
            pointer-events: none;
        }

        .react-tel-input .flag-dropdown {
            border-radius: 7px;
            height: 40px;
        }
        .react-tel-input .selected-flag {
            border-radius: 6px;
        }
    }
       
`


export const CommonFieldWrap = styled.div`
    margin-bottom:20px;
        .__error{
            display: block;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 1px;
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
            font-size: 0.6rem;
            color: #FF4D4F;
        }
        label{
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 5px;
            margin-left: 0;
            font-size: 0.7rem;
            display:flex;
            align-items:center;
        }

        .__link_block{
            display:flex;
            gap:15px;
            align-items:flex-end;
            button:disabled{
                background:#ccc !important;
            }
            button{
                height:40px;
                display:flex;
                justify-content: center;
                align-items:center;
                color:#fff;
                background:#666 !important;
                label{
                    margin:0;
                }
            }
        }

        .ant-checkbox-wrapper .ant-checkbox{
            margin-top:0px;
        }
        .__auto_com_serch{
            border-width: 1.5px;
            border-radius: 6px;
            border-color: #E1E1E1 !important;
            font-size: 0.8rem;
            background-color: white;
            .ant-select-selector {
                border: 1px solid #d9d9d9 !important;
            }    
        }
`
