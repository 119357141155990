import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const SearchWrapper = styled.div`
    position:relative;
    width:100%;     
    margin-bottom:30px;
`

export const SearchTextWrapper = styled.div`
    background: #00000003;
    padding: 20px;
    margin-top: 20px;
    border-radius: 18px;
    margin-bottom: 22px;
    display: flex;
    justify-content: start;
    gap: 20px;
    input{
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #ddd;
        height:55px;
    }
    button{
        width:150px !important;
        height:55px;
        font-size:18px;
        label{
            font-size:18px;
        }
    }


`

export const FilterWrapper = styled.div`
    position:relative;
    width:100%;
    background: #f7f7f7;
    padding: 15px;
    border-radius: 10px;

    .__filter_btn{
        display:flex;
        justify-content:end;
        button{
            height:30px;
            &:hover{
                background-color: #e9e9e9 !important;
            }
        }
    }

    .__sec{
        border-bottom: 1px solid #ddd;
        padding-bottom: 20px;
        margin-bottom:20px;
        h5{
            font-size:17px;
        }
    }

    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        .ant-checkbox{
            margin-top: 2px;
        }
    }
    
`

export const EventListWrapper = styled.div`
    position:relative;
    width:100%;     
    display: flex;
    gap: 20px;
    flex-direction: column;
`

export const AdvertiseWrapper = styled.div`
    position:relative;
    width:100%;     
`


export const MyEventItem = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr;
    justify-content: space-between;
    padding: 15px;
    background: #fff;
    box-shadow:0px 0px 10px #0001;
    min-height: 155px;
    width: 100%;
    color: #000;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.4s;
    gap: 15px;
    font-family: "Inter", sans-serif;
    border-radius: 10px;
        @media (max-width: 1025px) {
            min-height: 175px;
        }
        &:hover{
        box-shadow:0px 0px 10px #0001;
        }
        .__left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            p{
                margin: 0;
                color: #555;
                font-size: 14px;
                margin-bottom: 10px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            h1{
                font-size: 18px;
                margin: 0;
                font-weight: 700;
                color: ${colors.primary};
                margin-bottom: 8px;
            }
            span{
                color:#31281f;
                display:flex;
                align-items: center;
                gap:10px;
                font-size:13px;
                font-weight:300;
            }
            .__snips{
                margin-top:10px;
                display:flex;
                gap:10px;
                align-items:center;
                a{
                    font-size:13px;
                    padding:4px 7px;
                    background:#676767;
                    color:#fff;
                    border-radius:7px;
                }
            }
        }

        .___date{
            position:relative;
            img{
                width:100px;
                height:100px;
                object-fit:contain;
                padding: 10px;
                border:1px solid #fff;
                border-radius:5px;
                min-width:100px;
                background: #f5f5f5;
            }
            .__ticker {
                position: absolute;
                bottom: 10px;
                width: 20px;
                height: 20px;
                background: #8913ff;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 12px;
                left: 40%;
            }
            
        }
`


