import { BASE_URL } from "config/constants";


export const restAPIs = {

  login: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/h`
  }),

  listToi: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/list_toi`
  }),
  eventByCategory: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/events_by_category`
  }),
  featuredEvents: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/featured_events`
  }),
  myEvents: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/my_events`
  }),
  listCategory: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/list_category`
  }),
  listRegion: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/list_region`
  }),
  listSponsorships: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/list_sponsorships`
  }),
  listDelegateCount: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/list_delegate_count`
  }),

  commonAPI: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}`
  }),

  eventCategories: () => ({
    method: 'get',
    endpoint: `https://fxfewts762ccox6g6knxz3ck540idtkp.lambda-url.sa-east-1.on.aws/`
  }),

  eventList: (body:any) => ({
    method: 'post',
    body,
    endpoint: `https://fxfewts762ccox6g6knxz3ck540idtkp.lambda-url.sa-east-1.on.aws/`
  }),

  regionList: (body:any) => ({
    method: 'post',
    body,
    endpoint: `https://fxfewts762ccox6g6knxz3ck540idtkp.lambda-url.sa-east-1.on.aws/`
  }),

  backendApi: (body:any) => ({
    method: 'post',
    body,
    endpoint: `https://fxfewts762ccox6g6knxz3ck540idtkp.lambda-url.sa-east-1.on.aws/`
  }),
  
};