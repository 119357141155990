// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.css';
import { Modal } from 'antd';
import { ProfileCard, ProfileModalWrap } from './styles';
import { CheckCircleFilled } from '@ant-design/icons';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import AvatarImg from 'assets/images/avatar.webp';

import Button from 'components/Button'
import { useSelector } from 'react-redux';

const ProfileView = (props: any) => {
    const { onClose, open } = props;
    const { profileItem } = useSelector((store: any) => store.commonData);
    const [chartOptions, setChartOptions] = useState({});
    const [chartOptions2, setChartOptions2] = useState({});

    const handleClose = () => {
        onClose();
    }

    useEffect(() => {
        if (profileItem) {
            setChartOptions({
                chart: {
                    type: 'column',
                    height: 240
                },
                title: {
                    text: '',
                    align: 'left'
                },
                xAxis: {
                    categories: ['USA', 'China', 'Brazil', 'EU', 'India', 'Russia'],
                    crosshair: true,
                    accessibility: {
                        description: 'Countries'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: '1000 metric tons (MT)'
                    }
                },
                tooltip: {
                    valueSuffix: ' (1000 MT)'
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [
                    {
                        name: 'Corn',
                        data: [68300, 27500, 14500]
                    },
                    {
                        name: 'Wheat',
                        data: [141000, 107180, 77000]
                    }
                ]
            });

            setChartOptions2({
                chart: {
                    type: 'pie',
                    height: 240
                },
                title: {
                    text: '',
                    align: 'left'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        depth: 35,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Share',
                    data: [
                        ['Samsung', 23],
                        ['Apple', 18],
                        {
                            name: 'Xiaomi',
                            y: 12,
                            sliced: true,
                            selected: true
                        },
                        ['Oppo*', 9],
                        ['Vivo', 8],
                        ['Others', 30]
                    ]
                }]
            });
        }
    }, [profileItem]);
    return (
        <>
            <Modal
                open={open}
                onCancel={handleClose}
                className='__common_model_class __profile_model'
                footer={null}
                keyboard={false}
                title={profileItem?.firstName + ' ' + profileItem?.lastName}
                closable={true}
                maskClosable={false}
            >
                <ProfileModalWrap>
                    <ProfileCard>
                        <div>
                            <img src={profileItem?.dp || AvatarImg} alt="" />
                        </div>
                        <h1>{profileItem?.firstName} {profileItem?.lastName}</h1>
                        <p><a href={`mailto:${profileItem?.email}`}>{profileItem?.email}</a></p>
                        <p><a href={`tel:${profileItem?.countryCode}${profileItem?.mobile}`}>{profileItem?.countryCode} {profileItem?.mobile}</a></p>
                        <div className='__division'></div>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', }}>
                            <div style={{ width: '100%', height: '140px' }}>
                                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <HighchartsReact highcharts={Highcharts} options={chartOptions2} style={{ height: '140px' }} />
                            </div>
                        </div>
                    </ProfileCard>
                </ProfileModalWrap>
            </Modal>
        </>
    );
};

export default ProfileView;

ProfileView.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
