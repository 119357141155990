// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.css';
import { Modal, Upload, notification, message, Image } from 'antd';
import { CommonFieldWrap, ModalWrap } from './styles';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { ConnectionParameters } from './types';
import { validateEmail } from 'utils/helpers';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import Textarea from 'components/TextArea';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const AddConnection = (props: any) => {
    const { onClose, open, id, initialData, fetchUsers } = props;
    const { userDetails, connectionData } = useSelector((store: any) => store.commonData);
    const [messageApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();

    const initialFormState = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        countryCode: "+971"
    }

    const [form, setForm] = useState<any>(initialFormState);
    const [loading, setLoading] = useState(false);
    const { firstName, lastName, email, mobile, countryCode, organization, country, region, linkedInUrl, twitterId, notes, whatsappNum, designation } = form;
    const [errors, setErrors] = useState<any>(initialFormState);

    const [imageUrl, setImageUrl] = useState<string>();
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewImage, setPreviewOpenImage] = useState(false);
    const [previewImageImg, setPreviewImageImg] = useState('');

    useEffect(() => {
        if (connectionData) {
            setForm(connectionData);
            setFileList([{
                'url': connectionData?.dp,
                uid: '',
                name: '',
                thumbUrl: connectionData?.dp
            }])
        } else {
            setForm(initialFormState);
        }
    }, [connectionData]);

    const validateForm = (): boolean => {
        const newError = {
            firstName: firstName ? '' : 'Firstname is required',
            lastName: lastName ? '' : 'Lastname is required',
            email: email ? '' : 'Email is required',
            mobile: mobile ? '' : 'Contact is required',
            countryCode: '',
            organization: organization ? '' : 'Organization is required',
            country: country ? '' : 'Country is required',
            region: region ? '' : 'Region is required',
            linkedInUrl: linkedInUrl ? '' : 'Linked In URL is required',
            twitterId: twitterId ? '' : 'Twitter Id is required',
            designation : designation ? '' : 'Designation is required',
            whatsappNum : whatsappNum ? '' : 'Number is required',
            notes: ''

        }
        setErrors(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };


    const handleClose = () => {
        setForm(initialFormState)
        setErrors(initialFormState)
        setFileList([])
        onClose();
        dispatch({
            type: 'connectionData',
            value: null
        });

    }

    const handleSave = async () => {
        if (!validateForm()) return null;
        const payload: any = {
            endpoint: "/editContact",
            data: {
                firstName,
                lastName,
                email,
                countryCode,
                mobile,
                whatsappNum,
                designation,
                userId: userDetails?.data?.id,
                dp: fileList[0]?.thumbUrl,
                organization,
                country,
                region,
                linkedInUrl,
                twitterId,

                notes
            }
        };

        if (connectionData) {
            payload.data.connId = connectionData.id;
        }

        setLoading(true);
        const res = await fetchData(restAPIs.commonAPI(payload));

        if (res?.status === 'SUCCESS') {
            openNotificationWithIcon('success', 'Success', res?.message)
            fetchUsers();
            handleClose();
        }
        else {
            openNotificationWithIcon('error', 'Error', res?.message)
        }
        setLoading(false);

    }

    const handleKeyDown = (event: any) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }


    const handleChangePhone = (value: any, country: any, e: any, formattedValue: any) => {
        setForm({ ...form, 'countryCode': '+' + formattedValue });

    }

    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        setImageUrl(fileList[0]?.thumbUrl)
    }

    const handlePreviewImage = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
        setPreviewImageImg(file.url || (file.preview as string));
        setPreviewOpenImage(true);
    };

    return (
        <>
            <Modal
                open={open}
                onCancel={handleClose}
                className='__common_model_class ___add_connection'
                footer={null}
                keyboard={false}
                closable={true}
                maskClosable={false}
            >
                {contextHolder}
                <ModalWrap>
                    <h4 style={{ color: '#000' }}>{connectionData ? "Edit" : "Add"} Connection</h4>
                    <div>
                        <Upload
                            listType="picture-circle"
                            className="avatar-uploader"
                            fileList={fileList}
                            beforeUpload={() => false}
                            maxCount={1}
                            accept='image/*'
                            onChange={handleChange}
                            onPreview={handlePreviewImage}
                        >
                            {uploadButton}
                        </Upload>

                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewImage,
                                    onVisibleChange: (visible) => setPreviewOpenImage(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImageImg(''),
                                }}
                                src={previewImageImg}
                            />
                        )}
                    </div>
                    <div className='__grid_prop'>
                        <InputField
                            error={errors.firstName}
                            onChange={onchangeHandler("firstName")}
                            value={firstName}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="First Name"
                            label="First Name"
                            color="#000"
                            isMandatory={true}

                        />
                        <InputField
                            error={errors.lastName}
                            onChange={onchangeHandler("lastName")}
                            value={lastName}
                            marginBottom={20}
                            placeholder="Last Name"
                            label="Last Name"
                            color="#000"
                            isMandatory={true}
                        />
                    </div>
                    <div className='__grid_prop'>
                        <InputField
                            error={errors.email}
                            onChange={onchangeHandler("email")}
                            value={email}
                            marginBottom={20}
                            placeholder="Email"
                            label="Email"
                            color="#000"
                            isMandatory={true}
                        />

<InputField
                            error={errors.designation}
                            onChange={onchangeHandler("designation")}
                            value={designation}
                            marginBottom={20}
                            placeholder="Designation"
                            label="Designation"
                            color="#000"
                            isMandatory={true}
                        />


                        
                    </div>

                    <div className='__grid_prop'>
                    <div>
                            <div className='__phoneInp'>
                                <div className='__country'>
                                    <CommonFieldWrap>
                                        <label>{'Country Code'}</label>
                                        <div style={{ display: 'flex' }}>
                                            <PhoneInput
                                                placeholder=''
                                                country={'ae'}
                                                inputClass='__inp'
                                                value={countryCode}
                                                onChange={handleChangePhone}
                                                inputProps={{
                                                    name: 'countryCode'
                                                }}
                                                disableCountryCode={true}
                                                inputStyle={{ display: 'none' }}
                                            />
                                            <span className='__name_cls'>{countryCode}</span>
                                        </div>
                                    </CommonFieldWrap>

                                </div>
                                <InputField
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    error={errors.mobile}
                                    onChange={onchangeHandler("mobile")}
                                    value={mobile}
                                    marginBottom={20}
                                    isMandatory={true}
                                    placeholder="Phone"
                                    label="Phone"
                                    color="#000"
                                />
                            </div>
                        </div>
                    <div>
                            <div className='__phoneInp'>
                                <div className='__country'>
                                    <CommonFieldWrap>
                                        <label>{'Country Code'}</label>
                                        <div style={{ display: 'flex' }}>
                                            <PhoneInput
                                                placeholder=''
                                                country={'ae'}
                                                inputClass='__inp'
                                                value={countryCode}
                                                onChange={handleChangePhone}
                                                inputProps={{
                                                    name: 'countryCode'
                                                }}
                                                disableCountryCode={true}
                                                inputStyle={{ display: 'none' }}
                                            />
                                            <span className='__name_cls'>{countryCode}</span>
                                        </div>
                                    </CommonFieldWrap>

                                </div>
                                <InputField
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    error={errors.whatsappNum}
                                    onChange={onchangeHandler("whatsappNum")}
                                    value={whatsappNum}
                                    marginBottom={20}
                                    isMandatory={true}
                                    placeholder="WhatsApp No"
                                    label="WhatsApp No"
                                    color="#000"
                                />
                            </div>
                        </div>
                    </div>

                    <InputField
                        error={errors.organization}
                        onChange={onchangeHandler("organization")}
                        value={organization}
                        marginBottom={20}
                        placeholder="Organization"
                        label="Organization"
                        color="#000"
                        isMandatory={true}
                    />

                    <div className='__grid_prop'>
                        <InputField
                            error={errors.country}
                            onChange={onchangeHandler("country")}
                            value={country}
                            marginBottom={20}
                            placeholder="Country"
                            label="Country"
                            color="#000"
                            isMandatory={true}
                        />

                        <InputField
                            error={errors.region}
                            onChange={onchangeHandler("region")}
                            value={region}
                            marginBottom={20}
                            placeholder="Region"
                            label="Region"
                            color="#000"
                            isMandatory={true}
                        />
                    </div>
                    <div className='__grid_prop'>
                        <InputField
                            error={errors.linkedInUrl}
                            onChange={onchangeHandler("linkedInUrl")}
                            value={linkedInUrl}
                            marginBottom={20}
                            placeholder="Linked In URL"
                            label="Linked In URL"
                            color="#000"
                            isMandatory={true}
                        />

                        <InputField
                            error={errors.twitterId}
                            onChange={onchangeHandler("twitterId")}
                            value={twitterId}
                            marginBottom={20}
                            placeholder="Twitter ID"
                            label="Twitter ID"
                            color="#000"
                            isMandatory={true}
                        />
                    </div>

                    <Textarea
                        label="Notes"
                        placeholder="Notes"
                        value={notes}
                        onChange={onchangeHandler('notes')}
                        error={errors.notes}
                        height={250}
                        color="#222"
                    />

                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '35px' }}>
                        <Button type="primary" label='Submit' loading={loading} onClickHandler={() => handleSave()} width='120px' />
                    </div>
                </ModalWrap>
            </Modal>
        </>
    );
};

export default AddConnection;

AddConnection.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    initialData: PropTypes.object,
    fetchUsers: PropTypes.func
};
