
import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const ProfileModalWrap = styled.div`
    width:100%;
    h3{
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        color: #11be02;
    }
    h4{
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        margin-top:20px;
    }
    p{
        font-size:16px;
        margin-top:20px;
    }
    

`


export const ProfileCard = styled.div`
    padding: 10px;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    overflow:hidden;

    img{
        width:100px;
        height:100px;
        object-fit:cover;
        margin:0 auto;
    }
    p{
        margin:0;
        font-size:15px;
        color:#000;
    }
    h1{
        font-size:22px;
    }
    .__division{
        height:1px;
        width:100%;
        background:#eee;
        margin:20px 0
    }
`