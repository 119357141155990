import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const EventDetailWrapper = styled.div`
    width: 80%;
    padding: 5px;
    font-family: "Inter", sans-serif;
    margin: 0 auto;
    background: #fff;
    margin-top: 20vh;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #eee2;
`


export const EventBanner = styled.div`
    position: absolute;
    width: 100%;
    height: 30vh;
    z-index: -1;
    background: #00000009;
    img{
        width: 100%;
        height: 100%;
        object-fit:cover;
    }
`

export const EventTitleSec = styled.div`
    display: grid;
    grid-template-columns: 1fr 350px;
    gap: 15px;
    padding:10px;
    margin-bottom: 20px;

    .__qr_code{
    img{
    border-radius: 8px;
    border: 1px solid rgba(5, 5, 5, 0.06);}
    }
    @media (max-width: 1085px) {
        grid-template-columns: 1fr;
      }
    .__top_left {
        display: grid;
        grid-template-columns: 140px 1fr;
        gap: 20px;
        border-right: 4px solid #eee4;
        padding-right: 15px;

        @media (max-width: 1085px) {
            gap: 12px;
            grid-template-columns: 80px 1fr;
            border-right: none;
            padding-right: 0px;
          }
        
        .__img{
            img{
                width: 100%;
                height: 150px;
                object-fit: contain;
                border-radius: 5px;
                padding: 5px;
                border: 1px solid #eee;
                background: #00000009;
                @media (max-width: 1085px) {
                    height: 100px;
                  }
            }
        }
        .__detail{
            .__det_top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                a{
                    background:#ccc;
                    border-radius:5px;
                    padding:2px 10px;
                    margin-right:5px;
                    color:#fff;
                    font-size: 12px;
                    font-weight: 300;
                }
            }

            h5{
                margin:5px 0;
                margin-top:10px;
                font-size:15px;
                color:#ab0707;
            }
            h1{
                margin:5px 0;
                font-size: 26px;
                font-weight: 600;
            
            }
            p{
                margin:0;
                font-size:13px;
                margin-bottom:4px;
            }
            .__rating{
                color:var(--antd-primary-color)
            }
            .__location{
                color:${colors?.darkPrimary}
            }
        }
    }
    .__booth {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        
        @media (max-width: 785px) {
            gap:20px;
          }
        span{
            display: flex;
            gap: 10px;
            font-size: 13px;
            a{
                display: flex;
                gap: 10px;
                align-items:center;
            }
        }
        .__buttons {
            display: flex;
            gap: 10px;
            width:100%;
            button{
                background: #fff;
                border: 1px solid;
                padding: 10px 13px;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;

                @media (max-width: 1085px) {
                    padding: 8px;
                  }
            
            }
        }
    }
`

export const EventTabSection = styled.div`
h5{
    margin:0;
    margin-bottom:10px;
}
.__hightlights {
    padding: 20px;
    background: #ff8a0017;
    border-radius: 10px;
    margin-bottom:20px;
    ul{
        margin:0;
        li{
            white-space: pre-line;
            }
    }
}


.__slider_sec{
    .slick-track{
        width:auto !important;
    }
    .slick-slide{
        width:auto !important;
    }
    margin:30px 0;
    .__item{
        
    }
    img{
        object-fit:cover;
        max-width:280px !important;
        border-radius:10px;
        overflow:hidden;
        height:200px;
        margin-right:20px;
        
    }
    iframe{
        width:100%;
        height:100%;
        max-width:280px !important;
        border-radius:10px;
        overflow:hidden;
        height:200px;
        margin-right:20px;
        pointer-events:none;
    }
    .__div{
        cursor:pointer;
    }
}

.__tags
    { 
        span{
            display:inline-block;
        }
        div {
            background: #f1f9ff;
            border-radius: 3px;
            padding: 2px 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            width: max-content;
            float: left;
        }
    }
    
`

export const AddPost = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 5px;
    border-radius: .3rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    padding: 1rem;
    cursor:pointer;
    img{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100px;
        background-color:#ccc2;
    }
    input{
        padding: 0 20px;
        border-radius: 15px;
        border: 1px solid #eee;
        height: 50px;
        pointer-events:none;
    }
`

export const SinglePost = styled.div`
       border-radius: .3rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .18);
    padding: 1.2rem 1rem;
    margin-top: 20px;

    img{
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100px;
    }

    .__top_head{
        display: grid;
        grid-template-columns: 45px 1fr 20px;
        gap: 5px;
        margin-bottom:10px;
        h1{
            font-size:15px;
            margin:0;
        }
        .__three_dot{
            transform:rotate(90deg);
            border-radius:10px;
            display:flex;
            align-items:center;
            justify-content:center;
            font-size:20px;
            cursor:pointer;
        }
    }

    .__tags{
        a{
            padding:6px 10px;
            background:#ff980021;
            margin-right:10px;
            border-radius:50px;
        }
    }

`

export const EventDashboardGraphs = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 50px;
    margin: 30px 0px 15px;
    .__single{
        padding:20px 30px;
        border:1px solid #ccc;
        border-radius:20px;
    }
`


export const EventSponsor = styled.div`

`

export const CommonChartSec = styled.div`

    font-size: 17px;
    font-weight: 500;
    margin: 10px 0px;
    h3{
        font-size: 20px;
        margin: 0;
        margin-bottom: 7px;
        font-weight: 700;
    }

    h1{
        color: #779bf9;
        font-size: 52px;
        font-weight: 600;
    }

    .__content_sec_ev {
        padding: 20px;
        background: #f6f3f3;
        border-radius: 20px;
        text-align: center;
    }

`


export const SingleReview = styled.div`
   border-radius: .3rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .18);
    padding: 1.2rem 1rem;
    margin-top:20px;
    img{
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100px;
    }

    .__top_head{
        display: grid;
        grid-template-columns: 45px 1fr 20px;
        gap: 5px;
        margin-bottom:10px;
        h1{
            font-size:15px;
            margin:0;
        }
        .__three_dot{
            transform:rotate(90deg);
            border-radius:10px;
            display:flex;
            align-items:center;
            justify-content:center;
            font-size:20px;
            cursor:pointer;
        }
    }

    .__tags{
        a{
            padding:6px 10px;
            background:#ff980021;
            margin-right:10px;
            border-radius:50px;
        }
    }
        p{
        margin:0
        }

`
