import React, { useEffect, useState } from 'react';
import { Pie, Line, Bar } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  ArcElement, 
  Tooltip, 
  Legend, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  BarElement,
  ChartData,
  ChartOptions
} from 'chart.js';

ChartJS.register(
  ArcElement, 
  Tooltip, 
  Legend, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  BarElement
);

const API_URL = 'https://iffccnludwcengxsk7um6jinoa0gblxx.lambda-url.sa-east-1.on.aws/';

interface Event {
  value: string;
  label: string;
}

const Dashboard: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<string>('');
  const [metrics, setMetrics] = useState<any>(null);
  const [pieData, setPieData] = useState<ChartData<'pie'> | null>(null);
  const [lineData, setLineData] = useState<ChartData<'line'> | null>(null);
  const [barData1, setBarData1] = useState<ChartData<'bar'> | null>(null);
  const [barData2, setBarData2] = useState<ChartData<'bar'> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async (endpoint: string, eventId: string) => {
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ endpoint, data: { eventId } }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
      return null;
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsData = await fetchData('/listEventDropdown', '');
      if (eventsData && eventsData.events && Array.isArray(eventsData.events)) {
        const formattedEvents = eventsData.events.map((event: any) => ({
          value: event.eventId,
          label: event.eventTitle
        }));
        setEvents(formattedEvents);
      }
    };

    fetchEvents();
  }, []);

  const fetchAllData = async (eventId: string) => {
    setIsLoading(true);
    const [totalRegistrationData, pieData, lineData, barData1, barData2] = await Promise.all([
      fetchData('/totalRegistration', eventId),
      fetchData('/registrationBySource', eventId),
      fetchData('/registrationByChannel', eventId),
      fetchData('/registrationByAge', eventId),
      fetchData('/registrationByType', eventId)
    ]);

    setMetrics(totalRegistrationData);
    setPieData(pieData);
    setLineData(lineData);
    setBarData1(barData1);
    setBarData2(barData2);
    setIsLoading(false);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newEventId = event.target.value;
    setSelectedEvent(newEventId);
    if (newEventId) {
      fetchAllData(newEventId);
    } else {
      setMetrics(null);
      setPieData(null);
      setLineData(null);
      setBarData1(null);
      setBarData2(null);
    }
  };

  const pieOptions: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };

  const lineOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };

  const barOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, 
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div style={{ padding: '30px', backgroundColor: '#f7f7f9', fontFamily: 'Arial, sans-serif', color: '#333' }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginBottom: '30px' 
      }}>
        <select 
          value={selectedEvent} 
          onChange={handleEventChange} 
          style={{ 
            padding: '12px 18px', 
            borderRadius: '8px', 
            border: '1px solid #00b894', 
            fontSize: '16px',
            backgroundColor: '#ffffff', 
            color: selectedEvent ? '#333333' : '#757575',
            boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
            transition: 'all 0.3s',
            outline: 'none',
            cursor: 'pointer',
            minWidth: '200px',
            appearance: 'none',
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 12px center',
            paddingRight: '30px'
          }}
        >
          <option value="" disabled hidden>Select an event</option>
          {events.map((event) => (
            <option key={event.value} value={event.value}>{event.label}</option>
          ))}
        </select>
      </div>

      {!selectedEvent && (
        <p style={{ textAlign: 'center', fontSize: '18px', color: '#999' }}>
          Please select an event to view the dashboard.
        </p>
      )}

      {selectedEvent && isLoading && (
        <p style={{ textAlign: 'center', fontSize: '18px', color: '#999' }}>
          Loading dashboard data...
        </p>
      )}

      {selectedEvent && !isLoading && (
        <div>
          {metrics && (
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', 
              gap: '20px', 
              marginBottom: '30px' 
            }}>
              <div style={{ 
                backgroundColor: 'white', 
                padding: '20px', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
              }}>
                <h4 style={{ fontSize: '16px', marginBottom: '10px', fontWeight: '500', color: '#4a4a4a' }}>Total Registration</h4>
                <p style={{ fontSize: '26px', fontWeight: 'bold', margin: '0', color: '#00b894' }}>{metrics.totalRegistrations}</p>
              </div>
              <div style={{ 
                backgroundColor: 'white', 
                padding: '20px', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
              }}>
                <h4 style={{ fontSize: '16px', marginBottom: '10px', fontWeight: '500', color: '#4a4a4a' }}>Speakers</h4>
                <p style={{ fontSize: '26px', fontWeight: 'bold', margin: '0', color: '#0984e3' }}>{metrics.speakers}</p>
              </div>
              <div style={{ 
                backgroundColor: 'white', 
                padding: '20px', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
              }}>
                <h4 style={{ fontSize: '16px', marginBottom: '10px', fontWeight: '500', color: '#4a4a4a' }}>Exhibitors</h4>
                <p style={{ fontSize: '26px', fontWeight: 'bold', margin: '0', color: '#e17055' }}>{metrics.exhibitors}</p>
              </div>
              <div style={{ 
                backgroundColor: 'white', 
                padding: '20px', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
              }}>
                <h4 style={{ fontSize: '16px', marginBottom: '10px', fontWeight: '500', color: '#4a4a4a' }}>Delegates</h4>
                <p style={{ fontSize: '26px', fontWeight: 'bold', margin: '0', color: '#fdcb6e' }}>{metrics.delegates}</p>
              </div>
            </div>
          )}
          
          {pieData && lineData && barData1 && barData2 && (
            <div style={{ display: 'grid', gap: '30px' }}>
              <div style={{ 
                backgroundColor: 'white', 
                padding: '20px', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)', 
                marginBottom: '30px' 
              }}>
                <h4 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#4a4a4a' }}>Registration from Domains Breakdown</h4>
                <div style={{ height: '240px' }}>
                  <Pie data={pieData} options={pieOptions} />
                </div>
              </div>

              <div style={{ 
                backgroundColor: 'white', 
                padding: '20px', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)', 
                marginBottom: '30px' 
              }}>
                <h4 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#4a4a4a' }}>Registration Channels Overview</h4>
                <div style={{ height: '240px' }}>
                  <Line data={lineData} options={lineOptions} />
                </div>
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '30px' }}>
                <div style={{ 
                  backgroundColor: 'white', 
                  padding: '20px', 
                  borderRadius: '10px', 
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
                }}>
                  <h4 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#4a4a4a' }}>Age Distribution of Registrants</h4>
                  <div style={{ height: '240px' }}>
                    <Bar data={barData1} options={barOptions} />
                  </div>
                </div>

                <div style={{ 
                  backgroundColor: 'white', 
                  padding: '20px', 
                  borderRadius: '10px', 
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
                }}>
                  <h4 style={{ fontSize: '18px', marginBottom: '15px', fontWeight: '500', color: '#4a4a4a' }}> Registration Types Distribution</h4>
                  <div style={{ height: '240px' }}>
                    <Bar data={barData2} options={barOptions} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;