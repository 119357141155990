import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const ProfileWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 40px;
  padding-top: 40px;
  @media (max-width: 828px) {
    padding-left: 20px;
    padding-left: 30px;
  }
`;

export const ProfileInnerWrap = styled.div`
  display: flex;
  height: calc(100vh - 80px);
  
  .ant-tabs-tab {
    border-color: transparent;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      padding: 13px 16px;
      min-width: 180px;
    }
    .ant-tabs-tab-active {
      background: #f8f8f8 !important;
      border-right-color: transparent !important;
    }
    .ant-tabs-tab {
      background: #fff;
      border: 1px solid #f8f8f8;
    }
  }
  .ant-tabs-content-holder {
    border-radius: 0px;
    background: #f8f8f8;
    border: none;
    padding: 10px;
    border-color: transparent;
    min-height: 85vh;
  }
  .__search_sec {
    display: flex;
    align-items: center;
    gap: 10px;
    input {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-color: #d9d9d9;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
      outline: none;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      touch-action: manipulation;
      color: rgba(0, 0, 0, 0.88);
      padding: 4px 15px;
      border-radius: 6px;
      @media (max-width: 828px) {
        width: 100%;
      }
    }
  }
`;

export const ConnectionSingle = styled.div<any>`
  display: grid;
  grid-template-columns: 70px 1fr auto;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #0001;
  transition: 0.4s;
  border-color: ${(props) => props.borderColor};
  @media (max-width: 828px) {
    grid-template-columns: 70px 1fr;
  }
  @media (max-width: 388px) {
    grid-template-columns: 40px 1fr;
    gap: 10px;
  }
  .__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    button {
      @media (max-width: 388px) {
        padding: 0 10px;
      }
    }
  }
  p {
    margin: 0;
  }
  h5 {
    margin: 0;
  }
  .__buttons.approval {
    display: grid;
    grid-template-columns: 1fr 1fr 30px;
  }
  .__reject {
    background-color: #f44336 !important;
    border: 0 !important;
    box-shadow: none;
    min-width: 130px;
    @media (max-width: 488px) {
      min-width: 90px;
    }
  }
  .__accept {
    background-color: #4caf50 !important;
    border: 0 !important;
    box-shadow: none;
    min-width: 130px;
    @media (max-width: 488px) {
      min-width: 90px;
    }
  }
`;

export const EventItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #0001;
  @media (max-width: 588px) {
    grid-template-columns: 1fr;
  }
  .__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  h1 {
    font-size: 22px;
    color: ${colors?.primary};
  }
  h3 {
    font-size: 16px;
  }
`;

export const ProfileSettings = styled.div`
  padding: 30px 50px;
  margin-top: 30px;
  background: #fff;
  border-radius: 14px;
  width: 98%;
  margin-bottom: 20px;
  .__head {
    display: flex;
    justify-content: space-between;
    .__round {
      width: 70px;
      height: 70px;
      background: #469ff8;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      font-weight: 600;
      color: #fff;
      line-height: 1;
      padding-bottom: 3px;
      text-transform: capitalize;
    }
    .__share {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 2px;
      img {
        width: 100px;
        border-radius: 10px;
        border: 1px solid #ddd;
        height: 100px;
        background: #fff;
      }
    }
  }
  .__buttons {
    display: flex;
    gap: 20px;
    justify-content: end;
  }
`;

export const EventsListings = styled.div`
  margin-top: 30px;
  width: 98%;
  margin-bottom: 20px;
`;

export const CampaignSingle = styled.div<any>`
  display: grid;
  grid-template-columns: 40px 1fr auto;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #0001;
  transition: 0.4s;
  border-color: ${(props) => props.borderColor};
  @media (max-width: 828px) {
    grid-template-columns: 70px 1fr;
  }
  @media (max-width: 388px) {
    grid-template-columns: 40px 1fr;
    gap: 10px;
  }
  .__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    button {
      @media (max-width: 388px) {
        padding: 0 10px;
      }
    }
  }
  p {
    margin: 0;
  }
  h5 {
    margin: 0;
  }
  .__buttons.approval {
    display: grid;
    grid-template-columns: 1fr 1fr 30px;
  }
  .__reject {
    background-color: #f44336 !important;
    border: 0 !important;
    box-shadow: none;
    min-width: 130px;
    @media (max-width: 488px) {
      min-width: 90px;
    }
  }
  .__accept {
    background-color: #4caf50 !important;
    border: 0 !important;
    box-shadow: none;
    min-width: 130px;
    @media (max-width: 488px) {
      min-width: 90px;
    }
  }
`;
