// Assuming USER_DETAILS is a string constant in 'config/actionTypes'
import { ActionTypes } from 'utils/enums';
import initialState from './initialState';

// Reducer function
const commonData = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.USER_DETAILS:
      return {
        ...state,
        userDetails: action.value,
      };

    case ActionTypes.CONNECTION_DATA:
      return {
        ...state,
        connectionData: action.value,
      };

    case ActionTypes.EVENT_DATA:
      return {
        ...state,
        eventData: action.value
      }

    case ActionTypes.PROFILE_ITEM : 
    return {
      ...state,
      profileItem:  action.value
    }

    case ActionTypes.SEARCH_TEXT :
    return{
      ...state,
      searchText : action.value
    }

    case ActionTypes.FEED_DATA : 
    return{
      ...state,
      feedData : action.value
    }
    case ActionTypes.REVIEW_DATA : 
    return{
      ...state,
      reviewData : action.value
    }

    case ActionTypes.CAMPAIGN_DATA:
    return{
      ...state,
      campaignData : action.value
    }



    


    default:
      return state;
  }
};

export default commonData;
