import React, { ChangeEvent, useState } from "react";
import { Input } from 'antd';


import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

// Style
import { Label } from "assets/styles/globalStyles";
import { FontSizes, FontWeight } from "utils/enums";
import { colors } from "assets/styles/colors";
import { ContainerTextArea, ErrorLabel } from "./styles";

const { TextArea } = Input;

const Textarea = (props: any) => {
  const {
    placeholder,
    onChange,
    value = "",
    label,
    error,
    onKeyDown,
    marginTop,
    marginBottom,
    isMandatory,
    noLabel,
    noError,
    disabled,
    viewMode,
    color
  } = props;

  const onChangeHandle = (e:any) =>
    onChange?.(e.target.value);

  return (
    <ContainerTextArea {...{ marginBottom, marginTop, error: !!error }}>
      {label && (
        <Label
          marginBottom={viewMode ? 0 : 5}
          fontSize={FontSizes.floatingLabel}
          color={color || colors.primaryText}
          fontWeight={FontWeight.default}
        >
          {label} {isMandatory && !viewMode && <span>*</span>}
        </Label>
      )}
      
        <>
          <TextArea
            disabled={disabled}
            autoComplete="off"
            status={error ? "error" : ""}
            value={value as string}
            placeholder={placeholder || label}
            onChange={onChangeHandle}
            onKeyDown={onKeyDown}
          />
          {!noError && !!error && (
            <ErrorLabel
              fontSize={FontSizes.error}
              color={colors.error}
              fontWeight={FontWeight.light}
            >
              {error}
            </ErrorLabel>
          )}
        </>
      
    </ContainerTextArea>
  );
};

export default Textarea;
