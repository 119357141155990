import React, { useEffect, useRef, useState } from 'react';
import { Steps, Checkbox, Card, DatePicker, Divider, AutoComplete, message, notification } from 'antd';
import InputField from 'components/InputField';
import Textarea from 'components/TextArea';
import Button from 'components/Button';
import Select from 'components/Select';
import dayjs, { Dayjs } from 'dayjs';
import type { GetProps, InputRef } from 'antd';
import { Image, Upload } from 'antd';

import type { GetProp, CheckboxProps, UploadFile, UploadProps, DatePickerProps } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'antd';
import { CreateEventParameters, VideoParameter } from './types';
import { ButtonSec, CommonFieldWrap, CreateEventWrap } from './styles';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { validateURL } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];
const CheckboxGroup = Checkbox.Group;
const { Step } = Steps;
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CreateEvent: React.FC = () => {
    const [locationS, setLocation] = useState<string>('');
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState<any>({});
    const { userDetails } = useSelector((store: any) => store.commonData);
    const [eventCategories, seteventCategories] = useState<{ title: string, id: string }[]>([]);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = notification.useNotification();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpenEventLogo, setPreviewOpenEventLogo] = useState(false);
    const [previewImageEventLogo, setPreviewImageEventLogo] = useState('');
    const [previewOpenEventCover, setPreviewOpenEventCover] = useState(false);
    const [previewImageEventCover, setPreviewImageEventCover] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [eventCover, setEventCover] = useState<UploadFile[]>([])
    const [eventLogo, setEventLogo] = useState<UploadFile[]>([])
    const [regions, setRegion] = useState<any>([])
    const [cities, setCities] = useState<any>([])
    const [isLoadingStepOne, setLoadingStepOne] = useState<any>(false)
    const [form, setForm] = useState<CreateEventParameters>({
        title: "",
        description: "",
        dateFrom: "",
        dateTo: "",
        location: "",
        category: "",
        city: "",
        region: ""
    });

    // dayjs("2024-05-31")


    const [loading, setLoading] = useState(false);
    const { title, description, dateFrom, dateTo, location, category, city, region } = form;
    const [errors, setErrors] = useState<CreateEventParameters>({
        title: "",
        description: "",
        dateFrom: "",
        dateTo: "",
        location: "",
        category: "",
        city: "",
        region: ""
    });
    const [videoLinks, setVideoLinks] = useState<any>([])
    const [videoLink, setVideoLink] = useState<any>("")
    const [videoError, setVideoError] = useState('')
    const [tags, setTags] = useState<any>([])
    const [tag, setTag] = useState<any>("")
    const [userList, setUserList] = useState<any>([])
    const [users, setUsers] = useState<any>([])
    const [user, setUser] = useState<any>("")
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
    const [isSponsorship, setIsSponsorship] = useState(false)


    const onChangeSponsorhipEnabled: CheckboxProps['onChange'] = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setIsSponsorship(e.target.checked)
      };

    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFormSubmit = (data: any) => {
        setFormData({ ...formData, ...data });
        next();
    };

    const fetchEventCategories = async () => {
        const body = {
            "endpoint": "/list_category",
            "data": {}
        }
        const res = await fetchData(restAPIs.commonAPI(body));
        seteventCategories(res?.data?.eventCategories)
    };

    useEffect(() => {
        fetchEventCategories();
    }, []);

    const steps = [
        {
            title: 'Event Registration',
            key: 'eventregistration',
        },
        {
            title: 'Adding Users',
            key: 'addusers',
        },
        {
            title: 'Preview',
            key: 'preview',
        },
    ];

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day');
    };


    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handlePreviewEventLogo = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImageEventLogo(file.url || (file.preview as string));
        setPreviewOpenEventLogo(true);
    };

    const handlePreviewEventCover = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImageEventCover(file.url || (file.preview as string));
        setPreviewOpenEventCover(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }

    const handleChangeEventCover: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setEventCover(newFileList);
    }

    const handleChangeEventLogo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setEventLogo(newFileList);
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );


    const validateForm = (): boolean => {
        const newError = {
            title: title ? '' : 'Title is required',
            description: description ? '' : 'Description is required',
            dateFrom: dateFrom ? '' : 'Date From is required',
            dateTo: (dateTo ? '' : 'Date to is required') || (dateTo > dateFrom) ? '' : 'End date must be after start date.',
            location: location ? '' : 'Location is required',
            category: '',
            city: '',
            region: ''
        }
        setErrors(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };

    const onChangeDate = (identifier: string) => (date: any, dateString: any) => {
        setForm({
            ...form,
            [identifier]: dateString,
        });
    };

    const onchangeHandlerVideo = (value: string) => {
        setVideoLink(value)
    };

    const handleVideoAdd = () => {
        if (validateURL(videoLink)) {
            setVideoError('')
            setVideoLinks(videoLinks.concat(videoLink))
            setVideoLink('')
        }
        else
            setVideoError('Please enter a valid url')
    }

    const onchangeHandlerTag = (value: string) => {
        setTag(value)
    };

    const handleTagAdd = () => {
        setTags(tags.concat(tag))
        setTag('')
    }

    const handleSave = async () => {
        if (!validateForm()) return null;
        setLoadingStepOne(true);
        let payload = {
            'endpoint': "/add_event",
            'data': {
                'organizer': userDetails?.data?.user_id,
                'draft': true,
                'title': title,
                'desc': description,
                'startDate': dateFrom,
                'endDate': dateTo,
                'location': location,
                'category': category,
                'city': city,
                'region': region,
                'eventCover': eventCover[0]?.thumbUrl,
                'eventLogo': eventLogo[0]?.thumbUrl,
                'tags': tags,
                'videoLinks': videoLinks,
                'isSponsorshipEnabled': isSponsorship
            }
        }

        const res = await fetchData(restAPIs.commonAPI(payload));

        if (res?.status === 'SUCCESS') {
            openNotificationWithIcon('success', 'Success', res?.message)
            navigate('/home');
        }
        else {
            openNotificationWithIcon('error', 'Error', res?.message)
        }
        setLoadingStepOne(false);
    }

    const onchangeHandlerUser = (value: string) => {
        setUser(value)
    };

    const handleUserAdd = () => {
        setUsers(users.concat(user))
    }

    const checkAll = users.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < users.length;

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };

    const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
        setCheckedList(e.target.checked ? users : []);
    };

    const onSelectUserDropDown = (data: any, option: any) => {
        setUser(option.label);
    };

    const onChangeUserValue = (data: any, option: any) => {
        setUser(data);
    };

    const handleUserStepSkip = () => {

    }

    const handleStepOneNext = () => {
        if (!validateForm()) return null;
        next()
    }

    useEffect(() => {
        fetchRegions();
        fetchCities();
    }, [])

    const fetchRegions = async () => {
        let payload = {
            "endpoint": "/list_region",
            "data": {}
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        let tempList = []
        for (let index = 0; index < res?.data?.regions.length; index++) {
            const element = res?.data?.regions[index];
            tempList.push({ value: element?.id, label: element?.itemName })
        }
        setRegion(tempList)
    };

    const fetchCities = async () => {
        let payload = {
            "endpoint": "/list_city",
            "data": {}
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        let tempList = []
        for (let index = 0; index < res?.data?.city.length; index++) {
            const element = res?.data?.city[index];
            tempList.push({ value: element?.id, label: element?.itemName })
        }
        setCities(tempList)
    };

    const removeTag = (item: any, idx: any) => {
        const updatedTags = tags.filter((tagItem: any, index: any) => index !== idx);
        setTags(updatedTags);
    }

    const removeLink = (item: any, idx: any) => {
        const updatedLinks = videoLinks.filter((tagItem: any, index: any) => index !== idx);
        setVideoLinks(updatedLinks);
    }

    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };

    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 const latitude = position.coords.latitude;
    //                 const longitude = position.coords.longitude;
    //                 setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`);
    //             },
    //             (error) => {
    //                 console.error(error);
    //                 setLocation('Failed to retrieve location');
    //             }
    //         );
    //     } else {
    //         setLocation('Geolocation is not supported by this browser.');
    //     }
    // }, []);


    // const inputRef = useRef<HTMLInputElement>(null);

    // useEffect(() => {
    //     if (!window.google) return; // Check if the Google Maps API is loaded

    //     const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current!);
    //     autocomplete.addListener('place_changed', () => {
    //       const place = autocomplete.getPlace();
    //     });
    // }, []);

    return (
        <CreateEventWrap>
            {contextHolder}
            <Steps current={currentStep}>
                {steps.map((step, index) => (
                    <Step key={index} />
                ))}
            </Steps>
            <div className="steps-content">

                <h1 style={{ fontSize: '22px', fontWeight: '300', marginTop: '140px' }}>{steps[currentStep].title}</h1>

                {currentStep === 0 &&
                    <>
                        {/* <input ref={inputRef} placeholder="Enter a location" /> */}
                        <div className="steps-container __txtara">
                            <InputField
                                error={errors.title}
                                onChange={onchangeHandler("title")}
                                value={title}
                                placeholder="Title"
                                label="Event Title*"
                                color="#222"
                            />


                            <Select
                                onChangeHandler={onchangeHandler("category")}
                                label="Category"
                                options={eventCategories?.map(item => ({
                                    value: item.id,
                                    label: item.title
                                }))}
                                value={category}
                                error={errors.category}
                            />

                            <Textarea
                                label="Description*"
                                placeholder="Description"
                                value={description}
                                onChange={onchangeHandler('description')}
                                error={errors.description}
                                height={250}
                                color="#222"
                            />
                            <div className="steps-container" style={{ display: 'flex' }}>
                                <CommonFieldWrap>
                                    <label><strong>Event Cover</strong></label>
                                    <Upload
                                        listType="picture-card"
                                        fileList={eventCover}
                                        onPreview={handlePreviewEventCover}
                                        onChange={handleChangeEventCover}
                                        beforeUpload={() => false}
                                        maxCount={1}
                                        accept='image/*'
                                    >
                                        {uploadButton}
                                    </Upload>
                                    {previewImageEventCover && (
                                        <Image
                                            wrapperStyle={{ display: 'none' }}
                                            preview={{
                                                visible: previewOpenEventCover,
                                                onVisibleChange: (visible) => setPreviewOpenEventCover(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImageEventCover(''),
                                            }}
                                            src={previewImageEventCover}
                                        />
                                    )}
                                </CommonFieldWrap>


                                <CommonFieldWrap>
                                    <label><strong>Event Logo</strong></label>
                                    <Upload
                                        listType="picture-card"
                                        fileList={eventLogo}
                                        onPreview={handlePreviewEventLogo}
                                        onChange={handleChangeEventLogo}
                                        beforeUpload={() => false}
                                        maxCount={1}
                                        accept='image/*'
                                    >
                                        {uploadButton}
                                    </Upload>
                                    {previewImageEventLogo && (
                                        <Image
                                            wrapperStyle={{ display: 'none' }}
                                            preview={{
                                                visible: previewOpenEventLogo,
                                                onVisibleChange: (visible) => setPreviewOpenEventLogo(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImageEventLogo(''),
                                            }}
                                            src={previewImageEventLogo}
                                        />
                                    )}
                                </CommonFieldWrap>
                            </div>


                        </div>

                        <div className="steps-container-location" style={{ marginTop: '30px' }}>
                            <InputField
                                error={errors.location}
                                onChange={onchangeHandler("location")}
                                value={location}
                                placeholder="Location"
                                label="Location*"
                                color="#222"

                            />


                            {/* <div >
                            <label>City</label>
                            <AutoComplete
                                style={{ width: '100%', height: '40px' }}
                                value={city}
                                options={cities}
                                autoFocus={true}
                                className="__auto_com_serch"
                                filterOption={(inputValue, option) =>
                                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onSelect={onSelectUserDropDown}
                                onChange={onChangeUserValue}
                            />
                        </div> */}


                            <Select
                                onChangeHandler={onchangeHandler("city")}
                                label="City"
                                options={cities}
                                value={city}
                                error={errors.region}
                            />

                            <Select
                                onChangeHandler={onchangeHandler("region")}
                                label="Region"
                                options={regions}
                                value={region}
                                error={errors.region}
                            />
                        </div>
                        {/* <div className="steps-container" style={{ marginTop: '30px' }}>
                           

                             <CommonFieldWrap>
                                <label><strong>Pictures</strong></label>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    accept='image/*'
                                >
                                    {uploadButton}
                                </Upload>
                                {previewImage && (
                                    <Image
                                        wrapperStyle={{ display: 'none' }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                            </CommonFieldWrap> 
                        </div> */}

                        <div className="steps-container" style={{ marginTop: '30px' }}>
                            <CommonFieldWrap>
                                <label>Date From*</label>
                                <DatePicker
                                    disabledDate={disabledDate}
                                    onChange={onChangeDate('dateFrom')}
                                    status={errors.dateFrom ? "error" : ""}
                                />
                                <span className='__error'>{errors.dateFrom}</span>
                            </CommonFieldWrap>

                            <CommonFieldWrap>
                                <label>Date To*</label>
                                <DatePicker
                                    disabledDate={disabledDate}
                                    onChange={onChangeDate('dateTo')}
                                    status={errors.dateTo ? "error" : ""}
                                />
                                <span className='__error'>{errors.dateTo}</span>
                            </CommonFieldWrap>
                        </div>

                        <div className="steps-container" style={{ marginTop: '30px' }}>
                            <CommonFieldWrap>
                                <div className='__link_block'>
                                    <InputField
                                        onChange={onchangeHandlerVideo}
                                        value={videoLink}
                                        placeholder="Link"
                                        label="Video Links"
                                        color="#222"
                                        error={videoError}
                                    />
                                    <Button type="primary" disabled={!videoLink} label='Add' onClickHandler={() => handleVideoAdd()} />
                                </div>
                                {videoLinks?.length > 0 && <ul>
                                    {videoLinks.map((item: any, idx: any) => {
                                        return (
                                            <li>
                                                <p className='__line_break' style={{ marginTop: '10px', marginBottom: 0, fontSize: '13px' }} key={idx}><Tooltip style={{ height: '40px' }} title={item}><a href={item} target='_blank'>{item}</a></Tooltip> <span style={{ cursor: 'pointer' }}><CloseCircleOutlined onClick={() => removeLink(item, idx)} /></span></p></li>
                                        )
                                    })}
                                </ul>
                                }
                            </CommonFieldWrap>

                            <CommonFieldWrap>
                                <div className='__link_block'>
                                    <InputField
                                        onChange={onchangeHandlerTag}
                                        value={tag}
                                        placeholder="Tag"
                                        label="Tags"
                                        color="#222"

                                    />
                                    <Button type="primary" disabled={!tag} label='Add' onClickHandler={() => handleTagAdd()} />
                                </div>

                                {tags?.length > 0 && <div style={{ marginTop: '10px' }}>
                                    {tags && tags.map((item: any, idx: any) => {
                                        return (
                                            <Tag closeIcon={<CloseCircleOutlined />}
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    removeTag(item, idx);
                                                }}
                                                key={idx}
                                                style={{ marginBottom: '10px' }}
                                            >
                                                {item}
                                            </Tag>
                                        )
                                    })}

                                </div>}
                            </CommonFieldWrap>

                            <CommonFieldWrap>
                                <Checkbox onChange={onChangeSponsorhipEnabled}>Sponsorship Enabled</Checkbox>
                            </CommonFieldWrap>
                        </div>

                        <ButtonSec>
                            <Button type="primary" label='Save and Exit' loading={isLoadingStepOne} onClickHandler={() => handleSave()} width='120px' />
                            <Button type="primary" label='Next' disabled onClickHandler={() => handleStepOneNext()} backgroundColor='#555 !important' width='80px' />
                        </ButtonSec>
                    </>
                }

                {currentStep === 1 &&
                    <>

                        <CommonFieldWrap>
                            <div className='__link_block' style={{ marginBottom: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label>Emails</label>
                                    <AutoComplete
                                        style={{ width: '100%', height: '40px' }}
                                        value={user}
                                        options={userList}
                                        autoFocus={true}
                                        className="__auto_com_serch"
                                        filterOption={(inputValue, option) =>
                                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onSelect={onSelectUserDropDown}
                                        onChange={onChangeUserValue}
                                    />
                                </div>

                                <Button type="primary" disabled={!user} label='Add' onClickHandler={() => handleUserAdd()} />
                            </div>

                            {users.length > 0 && <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                Check all
                            </Checkbox>}
                            <Divider />
                            <CheckboxGroup options={users} value={checkedList} onChange={onChange} />
                        </CommonFieldWrap>

                        <ButtonSec>
                            <Button type="primary" label='Previous' onClickHandler={prev} backgroundColor='#555' width='90px' />
                            <Button type="primary" label='Skip for Now' width='120px' />
                            {/* <Button type="primary" label='Next' onClickHandler={next} backgroundColor='#555' width='70px' /> */}
                        </ButtonSec>
                    </>
                }


            </div>

        </CreateEventWrap>
    );
};

export default CreateEvent;
