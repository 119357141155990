
// Core
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.css';
import { Checkbox, Modal, message, notification } from 'antd';
import { ModalWrap } from './styles';
import InputField from 'components/InputField';
import Textarea from 'components/TextArea';
import Button from 'components/Button';
import Select from 'components/Select';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, GetProps, InputRef } from 'antd';
import { Image, Upload } from 'antd';

import type { GetProp, CheckboxProps, UploadFile, UploadProps, DatePickerProps } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'antd';
import { EditEventParameters, VideoParameter } from './types';
import { ButtonSec, CommonFieldWrap, CreateEventWrap } from './styles';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { validateURL } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];


const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const EditEvent = (props: any) => {
    const { onClose, open } = props;

    const [currentStep, setCurrentStep] = useState(0);
    const { userDetails, eventData } = useSelector((store: any) => store.commonData);
    const dispatch = useDispatch();
    const [eventCategories, setEventCategories] = useState<{ title: string, id: string }[]>([]);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = notification.useNotification();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpenEventLogo, setPreviewOpenEventLogo] = useState(false);
    const [previewImageEventLogo, setPreviewImageEventLogo] = useState('');
    const [previewOpenEventCover, setPreviewOpenEventCover] = useState(false);
    const [previewImageEventCover, setPreviewImageEventCover] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [eventCover, setEventCover] = useState<any>([]);
    const [eventLogo, setEventLogo] = useState<any>([]);
    const [regions, setRegion] = useState<any>([]);
    const [cities, setCities] = useState<any>([]);
    const [isLoadingStepOne, setLoadingStepOne] = useState<any>(false);
    const [form, setForm] = useState<EditEventParameters>({
        title: '',
        description: '',
        dateFrom: '',
        dateTo: '',
        location: '',
        category: '',
        city: '',
        region: '',
    });
    const [loading, setLoading] = useState(false);
    const { title, description, dateFrom, dateTo, location, category, city, region } = form;
    const [errors, setErrors] = useState<EditEventParameters>({
        title: '',
        description: '',
        dateFrom: '',
        dateTo: '',
        location: '',
        category: '',
        city: '',
        region: '',
    });
    const [videoLinks, setVideoLinks] = useState<string[]>([]);
    const [videoLink, setVideoLink] = useState<string>('');
    const [videoError, setVideoError] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [tag, setTag] = useState<string>('');
    const [isSponsorship, setIsSponsorship] = useState(false)

    const fetchEventCategories = async () => {
        const body = {
            endpoint: '/list_category',
            data: {},
        };
        const res = await fetchData(restAPIs.commonAPI(body));
        setEventCategories(res?.data?.eventCategories);
    };

    const fetchRegions = async () => {
        let payload = {
            "endpoint": "/list_region",
            "data": {}
        }
        const res = await fetchData(restAPIs.commonAPI(payload));

        let tempList = []
        for (let index = 0; index < res?.data?.regions.length; index++) {
            const element = res?.data?.regions[index];
            tempList.push({ value: element?.id, label: element?.itemName })
        }
        setRegion(tempList)
    };

    const fetchCities = async () => {
        let payload = {
            "endpoint": "/list_city",
            "data": {}
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        let tempList = []
        for (let index = 0; index < res?.data?.city.length; index++) {
            const element = res?.data?.city[index];
            tempList.push({ value: element?.id, label: element?.itemName })
        }
        setCities(tempList)
    };

    useEffect(() => {
        if(eventData)
        {setForm({
            title: eventData?.dataSet?.title,
            description: eventData?.dataSet?.desc,
            dateFrom: dayjs(eventData?.dataSet?.startDate),
            dateTo: dayjs(eventData?.dataSet?.endDate),
            location: eventData?.dataSet?.location,
            category: eventData?.dataSet?.category,
            city: eventData?.dataSet?.city,
            region: eventData?.dataSet?.region,
        });
        setTags(eventData?.dataSet?.tags);
        setVideoLinks(eventData?.dataSet?.videoLinks);
        setEventCover(eventData?.dataSet?.eventCover ? [{ url: eventData?.dataSet.eventCover }] : []);
        setEventLogo(eventData?.dataSet?.eventLogo ? [{ url: eventData?.dataSet.eventLogo }] : []);
        setIsSponsorship(eventData?.dataSet?.isSponsorshipEnabled)
        fetchEventCategories();
        fetchRegions();
        fetchCities();}
    }, [eventData]);

    const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day');
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handlePreviewEventLogo = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImageEventLogo(file.url || (file.preview as string));
        setPreviewOpenEventLogo(true);
    };

    const handlePreviewEventCover = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImageEventCover(file.url || (file.preview as string));
        setPreviewOpenEventCover(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const handleChangeEventCover: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setEventCover(newFileList);
    };

    const handleChangeEventLogo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setEventLogo(newFileList);
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const validateForm = (): boolean => {
        const newError = {
            title: title ? '' : 'Title is required',
            description: description ? '' : 'Description is required',
            dateFrom: dateFrom && dateFrom.isValid() ? '' : 'Start date is required',
            dateTo: dateTo && dateTo.isValid() && dateTo.isAfter(dateFrom)
                ? ''
                : 'End date must be after start date.',
            location: location ? '' : 'Location is required',
            category: '',
            city: '',
            region: '',
        };
        setErrors(newError);
        return !Object.values(newError).some((item) => !!item);
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };

    const onChangeDate = (identifier: string) => (date: any, dateString: any) => {
        setForm({
            ...form,
            [identifier]: dayjs(dateString),
        });
    };



    const onchangeHandlerVideo = (value: string) => {
        setVideoLink(value);
    };

    const handleVideoAdd = () => {
        if (validateURL(videoLink)) {
            setVideoError('');
            setVideoLinks([...videoLinks, videoLink]);
            setVideoLink('');
        } else {
            setVideoError('Please enter a valid url');
        }
    };

    const onchangeHandlerTag = (value: string) => {
        setTag(value);
    };

    const handleTagAdd = () => {
        setTags([...tags, tag]);
        setTag('');
    };

    const handleSave = async () => {
        if (!validateForm()) return null;
        setLoadingStepOne(true);
        const eventCoverUrl = eventCover[0]?.url || (eventCover[0]?.originFileObj ? await getBase64(eventCover[0].originFileObj) : '');
        const eventLogoUrl = eventLogo[0]?.url || (eventLogo[0]?.originFileObj ? await getBase64(eventLogo[0].originFileObj) : '');

        let payload = {
            endpoint: '/edit_event',
            data: {
                eventId: eventData?.dataSet?.id,
                eventDetails: {
                    organizer: userDetails?.data?.user_id,
                    draft: true,
                    title,
                    desc: description,
                    startDate: dateFrom.format('YYYY-MM-DD'),
                    endDate: dateTo.format('YYYY-MM-DD'),
                    location,
                    category,
                    city,
                    region,
                    eventCover: eventCoverUrl,
                    eventLogo: eventLogoUrl,
                    tags,
                    videoLinks,
                    isSponsorshipEnabled: isSponsorship
                }
            },
        };

        const res = await fetchData(restAPIs.commonAPI(payload));
        if (res?.status === 'SUCCESS') {
            openNotificationWithIcon('success', 'Success', res?.message);
            setLoadingStepOne(false);
            eventData?.fetchEvents();
            onClose();
            setForm({
                title: '',
                description: '',
                dateFrom: '',
                dateTo: '',
                location: '',
                category: '',
                city: '',
                region: '',
            });
            setEventCover([]);
            setEventLogo([]);
            setVideoLinks([]);
            setTags([]);
        } else {
            openNotificationWithIcon('error', 'Error', res?.message);
            setLoadingStepOne(false);
        }
    };

    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        notification[type]({
            message,
            description,
        });
    };

    const handleClose = () => {
        setForm({
            title: '',
            description: '',
            dateFrom: '',
            dateTo: '',
            location: '',
            category: '',
            city: '',
            region: '',
        });
        setEventCover([]);
        setEventLogo([]);
        setVideoLinks([]);
        setTags([]);
        setCurrentStep(0);
        dispatch({
            type: 'eventData',
            value: ''
        });
        setLoadingStepOne(false);
        onClose();
    };
    const removeTag = (item: any, idx: any) => {
        const updatedTags = tags.filter((tagItem: any, index: any) => index !== idx);
        setTags(updatedTags);
    }

    const removeLink = (item: any, idx: any) => {
        const updatedLinks = videoLinks.filter((tagItem: any, index: any) => index !== idx);
        setVideoLinks(updatedLinks);
    }

    const onChangeSponsorhipEnabled: CheckboxProps['onChange'] = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setIsSponsorship(e.target.checked)
      };


    return (
        <Modal
            title="Edit Event"
            visible={open}
            onCancel={handleClose}
            footer={null}
            width={800}
        >
            <ModalWrap>
                <div className="steps-content">

                    <div className="steps-container __txtara">
                        <InputField
                            error={errors.title}
                            onChange={onchangeHandler("title")}
                            value={title}
                            placeholder="Title"
                            label="Event Title*"
                            color="#222"
                        />


                        <Select
                            onChangeHandler={onchangeHandler("category")}
                            label="Category"
                            options={eventCategories?.map(item => ({
                                value: item.id,
                                label: item.title
                            }))}
                            value={category}
                            error={errors.category}
                        />

                        <Textarea
                            label="Description*"
                            placeholder="Description"
                            value={description}
                            onChange={onchangeHandler('description')}
                            error={errors.description}
                            height={250}
                            color="#222"
                        />
                        <div className="steps-container" style={{ display: 'flex' }}>
                            <CommonFieldWrap>
                                <label><strong>Event Cover</strong></label>
                                <Upload
                                    listType="picture-card"
                                    fileList={eventCover}
                                    onPreview={handlePreviewEventCover}
                                    onChange={handleChangeEventCover}
                                    beforeUpload={() => false}
                                    maxCount={1}
                                    accept='image/*'
                                >
                                    {uploadButton}
                                </Upload>
                                {previewImageEventCover && (
                                    <Image
                                        wrapperStyle={{ display: 'none' }}
                                        preview={{
                                            visible: previewOpenEventCover,
                                            onVisibleChange: (visible) => setPreviewOpenEventCover(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImageEventCover(''),
                                        }}
                                        src={previewImageEventCover}
                                    />
                                )}
                            </CommonFieldWrap>


                            <CommonFieldWrap>
                                <label><strong>Event Logo</strong></label>
                                <Upload
                                    listType="picture-card"
                                    fileList={eventLogo}
                                    onPreview={handlePreviewEventLogo}
                                    onChange={handleChangeEventLogo}
                                    beforeUpload={() => false}
                                    maxCount={1}
                                    accept='image/*'
                                >
                                    {uploadButton}
                                </Upload>
                                {previewImageEventLogo && (
                                    <Image
                                        wrapperStyle={{ display: 'none' }}
                                        preview={{
                                            visible: previewOpenEventLogo,
                                            onVisibleChange: (visible) => setPreviewOpenEventLogo(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImageEventLogo(''),
                                        }}
                                        src={previewImageEventLogo}
                                    />
                                )}
                            </CommonFieldWrap>
                        </div>


                    </div>

                    <div className="steps-container-location" style={{ marginTop: '30px' }}>
                        <InputField
                            error={errors.location}
                            onChange={onchangeHandler("location")}
                            value={location}
                            placeholder="Location"
                            label="Location*"
                            color="#222"

                        />
                        <Select
                            onChangeHandler={onchangeHandler("city")}
                            label="City"
                            options={cities}
                            value={city}
                            error={errors.region}
                        />

                        <Select
                            onChangeHandler={onchangeHandler("region")}
                            label="Region"
                            options={regions}
                            value={region}
                            error={errors.region}
                        />
                    </div>


                    <div className="steps-container" style={{ marginTop: '30px' }}>
                        <CommonFieldWrap>
                            <label>Date From*</label>
                            <DatePicker
                                disabledDate={disabledDate}
                                onChange={onChangeDate('dateFrom')}
                                status={errors.dateFrom ? "error" : ""}
                                value={dateFrom}
                            />
                            <span className='__error'>{errors.dateFrom}</span>
                        </CommonFieldWrap>

                        <CommonFieldWrap>
                            <label>Date To*</label>
                            <DatePicker
                                disabledDate={disabledDate}
                                onChange={onChangeDate('dateTo')}
                                status={errors.dateTo ? "error" : ""}
                                value={dateTo}
                            />
                            <span className='__error'>{errors.dateTo}</span>
                        </CommonFieldWrap>
                    </div>

                    <div className="steps-container" style={{ marginTop: '30px' }}>
                        <CommonFieldWrap>
                            <div className='__link_block'>
                                <InputField
                                    onChange={onchangeHandlerVideo}
                                    value={videoLink}
                                    placeholder="Link"
                                    label="Video Links"
                                    color="#222"
                                    error={videoError}
                                />
                                <Button type="primary" disabled={!videoLink} label='Add' onClickHandler={() => handleVideoAdd()} />
                            </div>
                            {videoLinks?.length > 0 && <ul>
                                {videoLinks.map((item: any, idx: any) => {
                                    return (
                                        <li>
                                            <p className='__line_break' style={{ marginTop: '10px', marginBottom: 0, fontSize: '13px' }} key={idx}><Tooltip style={{ height: '40px' }} title={item}><a href={item} target='_blank'>{item}</a></Tooltip> <span style={{ cursor: 'pointer' }}><CloseCircleOutlined onClick={() => removeLink(item, idx)} /></span></p></li>
                                    )
                                })}
                            </ul>
                            }
                        </CommonFieldWrap>

                        <CommonFieldWrap>
                            <div className='__link_block'>
                                <InputField
                                    onChange={onchangeHandlerTag}
                                    value={tag}
                                    placeholder="Tag"
                                    label="Tags"
                                    color="#222"

                                />
                                <Button type="primary" disabled={!tag} label='Add' onClickHandler={() => handleTagAdd()} />
                            </div>

                            {tags?.length > 0 && <div style={{ marginTop: '10px' }}>
                                {tags && tags.map((item: any, idx: any) => {
                                    return (
                                        <Tag closeIcon={<CloseCircleOutlined />}
                                            onClose={(e) => {
                                                e.preventDefault();
                                                removeTag(item, idx);
                                            }}
                                            key={idx}
                                            style={{ marginBottom: '10px' }}
                                        >
                                            {item}
                                        </Tag>
                                    )
                                })}

                            </div>}
                        </CommonFieldWrap>

                        <CommonFieldWrap>
                                <Checkbox onChange={onChangeSponsorhipEnabled} value={isSponsorship}>Sponsorship Enabled</Checkbox>
                            </CommonFieldWrap>
                    </div>

                    <ButtonSec>
                        <Button type="primary" label='Update' loading={isLoadingStepOne} onClickHandler={() => handleSave()} width='120px' />
                    </ButtonSec>
                </div>
            </ModalWrap>
        </Modal>
    );
};

EditEvent.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EditEvent;
