
import styled from "styled-components";

export const ModalWrap = styled.div`
    
width: 100%;
margin: 0 auto;
padding-top:0;
@media (max-width: 1085px) {
    width: 100%;
  }
.ant-steps{
    position: fixed;
    background: #fff;
    padding: 40px 0;
    width: calc(70% - 60px);
    z-index: 1;
    backdrop-filter: blur(10px);
    @media (max-width: 1085px) {
        width: calc(100% - 60px);
        margin-left: -1px;
      }

    @media (max-width: 585px) {
        flex-direction:row !important;
      }
}

.__txtara{
    textarea{
        height:71px;
    }
}

.steps-container{
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
    width:100%;
    white-space: pre-line;
    word-break: break-word;
    @media (max-width: 585px) {
        grid-template-columns: 1fr;
      }
    
}

.steps-container-pictures{
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    width:100%;
    @media (max-width: 585px) {
        grid-template-columns: 1fr;
      }
}

.steps-container-location{
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    width:100%;
    @media (max-width: 585px) {
        grid-template-columns: 1fr;
      }
}
       
`

export const CreateEventWrap = styled.div`
`

export const CommonFieldWrap = styled.div`
        .__error{
            display: block;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 1px;
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
            font-size: 0.6rem;
            color: #FF4D4F;
        }
        label{
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 5px;
            margin-left: 0;
            font-size: 0.7rem;
            display:flex;
            align-items:center;
        }

        .__link_block{
            display:flex;
            gap:15px;
            align-items:flex-end;
            button:disabled{
                background:#ccc !important;
            }
            button{
                height:40px;
                display:flex;
                justify-content: center;
                align-items:center;
                color:#fff;
                background:#666 !important;
                label{
                    margin:0;
                }
            }
            
        }

        .ant-checkbox-wrapper .ant-checkbox{
            margin-top:0px;
        }
        .__auto_com_serch{
            border-width: 1.5px;
            border-radius: 6px;
            border-color: #E1E1E1 !important;
            font-size: 0.8rem;
            background-color: white;
            .ant-select-selector {
                border: 1px solid #d9d9d9 !important;
            }    
        }

        .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container,
        .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select
        {
            width: 72px;
            height: 72px;
        }
        .__line_break{
            display: flex;
            align-items: center;
             a{
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
        }}
`


export const ButtonSec = styled.div`
    display:flex;
    gap:15px;
    justify-content:end;
    align-items:center;
    margin-top:40px;
`


