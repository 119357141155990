import { colors } from 'assets/styles/colors';
import { FontSizes } from 'utils/enums';
import styled from "styled-components";

export const Wrapper = styled.div`
    .ant-select {
      height: 40px;
      width: 100% !important;
      
      .ant-select-selection-item {
        font-size: 0.8rem;
        color: ${colors.primaryText};
      }
      
      .ant-select-selector {
        border: 1.5px solid ${colors.grey2};
        border-radius: 6px;
      }

      &:hover {
        .ant-select-selector {
        border: 1.5px solid ${colors.grey2} !important;
      }
      }
    }

    .ant-select-selection-placeholder {
      font-size: ${FontSizes.floatingLabel}rem !important;
      color: ${colors.grey1} !important;
    }

`;