import styled from "styled-components";


export const HeaderOuter = styled.div<any>`
    width: 100%;
    padding: 5px 20px;
    position: fixed;
    top: 0;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #f7f7f7;
    box-shadow: 0px 0px 10px #0003;
    border-bottom: 2px solid;
    border-color: ${(props) => props.borderColor};
  `;

export const Logo = styled.a`
    display:flex;
    gap:10px;
    align-items:center;
        img{
            width:30px;
        }

        .___spnsr_bn{
            position: absolute;
            right: 0;
            font-size: 9px;
            bottom: -12px;
            border: 0;
            background: #8913ff;
            color: #fff;
            font-weight: 600;
            border-radius: 20px;
        }
`

export const RightMenu = styled.div<any>`
    display:flex;
    align-items:center;
    gap:10px;

    .__bal{
    img{
    background:#fff;
    }}
    

    a{
        border: none;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        padding: 6px 15px;
        font-size: 13px;
        color: #6b6b6b;
        background: #fff;
        cursor:pointer;
        @media (max-width: 1025px) {
            padding: 6px 7px;
          }
    }

    button{
        cursor:pointer;
        border: none;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #fff;
        background: ${(props) => props.backgroundColor};
        border-radius: 70px;
        padding: 6px 15px;
        transition:0.4s;
        @media (max-width: 1025px) {
            padding: 6px 7px;
          }
            &:hover{
                opacity:0.9;
            }
    }

    `

    export const SearchSection = styled.div`
        display:flex;
        align-items:center;
        gap:10px;
        
        input{
            padding: 0 22px;
            width: 380px;
            height: 35px;
            border-radius: 65px;
            border: 1px solid #0003;
            outline: none;
            font-size: 13px;
            @media (max-width: 1025px) {
                width: 150px;
              }
        }
        button{
            background:transparent;
            border:none;
            font-size:20px;
            cursor:pointer;
            color:#00000096;
            line-height:1;
        }
`


export const DrawerItems = styled.div`
      display: flex;
      flex-direction:column;
      gap:15px;
      a{
        color:#000;
        font-size:16px;
      }
      .active {
        font-weight: 600;
        color: #dab2ff;
    }
  
`;
