// Core
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.css';
import { Checkbox, CheckboxProps, GetProp, Modal, Tooltip, Upload, UploadFile, UploadProps, message } from 'antd';
import { CommonFieldWrap, DontAccount, ModalWrap, OTPTemplateChange, SuccessModalWrap } from './styles';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { ResetPassword, SignInParameters, SignUpParameters } from './types';
import { validateEmail } from 'utils/helpers';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch, useSelector } from 'react-redux';
import Textarea from 'components/TextArea';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled, InfoCircleFilled, PlusOutlined } from '@ant-design/icons';
import Select from 'components/Select';
import { colors, updatePrimaryColor } from 'assets/styles/colors';
import Success from 'modals/Success';


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });


const Login = (props: any) => {
    const { onClose, open } = props;
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [islogin, setIsLogin] = useState(true);
    const [isResetPassword, setResetPassword] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [file, setFile] = useState<any>('')
    const [checked, setChecked] = useState(false);

    const [tempLoginData, setLoginTempData] = useState<any>('')

    const [formSignup, setFormSignup] = useState<SignUpParameters>({
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        signupPassword: "",
        // userType: ""
    });

    const [loadingSignup, setLoadingSignup] = useState(false);

    const [errorsSignup, setErrorsSignup] = useState<SignUpParameters>({
        firstName: "",
        lastName: "",
        email: "",
        contact: "",
        signupPassword: "",
        // userType: ""
    });

    const {
        firstName,
        lastName,
        email,
        contact,
        signupPassword,
        // userType
    } = formSignup



    const validateFormSignup = (): boolean => {
        const newError = {
            firstName: firstName ? '' : 'Fist Name is required',
            lastName: lastName ? '' : 'Last Name is required',
            email: validateEmail(email) ? '' : 'Please enter a valid email address',
            contact: contact ? '' : 'Phone is required',
            signupPassword: signupPassword ? '' : "Password is required",
            // userType: userType ? '' : "User Type is required"
        }
        setErrorsSignup(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandlerSignup = (key: string) => (value: string) => {
        setFormSignup({
            ...formSignup,
            [key]: value,
        });
    };



    const onchangeHandleUserType = (value: any) => {

        // setFormSignup({
        //     ...formSignup,
        //     ['userType']: value,
        // });
    }


    const handleSaveSignup = async () => {
        if (!validateFormSignup()) return null;
        setLoadingSignup(true);
        let payload = {
            "endpoint": "/add_user",
            "data": {
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "username": email,
                "contact": contact,
                "password": await getSHA256Hash(signupPassword),
                // "userType": "organiser",
                "isOrganizer": checked
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        setLoadingSignup(false)
        if (res?.data?.user_id) {
            dispatch({
                type: 'userDetails',
                value: res
            });
            messageApi.open({
                type: 'success',
                content: res?.message,
            });
            localStorage.setItem('userDetails', JSON.stringify(res));

            if (checked) {
                setSuccesModel(true)
            }
            else
                handleClose();
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.data,
            });
        }
    }


    const [form, setForm] = useState<SignInParameters>({
        username: "",
        password: ""
    });

    const [loading, setLoading] = useState(false);
    const { username, password } = form;

    const [errors, setErrors] = useState<SignInParameters>({
        username: "",
        password: ""
    });

    const validateForm = (): boolean => {
        const newError = {
            username: username ? '' : 'Username is required',
            password: password ? '' : 'Password is required',
        }
        setErrors(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };


    const handleSave = async () => {
        if (!validateForm()) return null;
        setLoading(true);
        let payload = {
            "endpoint": "/login",
            "data": {
                "username": username,
                "password": await getSHA256Hash(password),
                "userType": "attendee"
            }
            
        }
        const res = await fetchData(restAPIs.commonAPI(payload));

        setLoading(false)
        if (res?.data && Object.keys(res.data).length !== 0) {

            if (res?.data?.id && !res?.data?.reset) {
                if (res?.data?.userType == 'sponsor')
                    updatePrimaryColor('#8913ff');
                if (res?.data?.userType == 'organizer')
                    updatePrimaryColor('#469ff8');

                setLoginTempData(res)
                dispatch({
                    type: 'userDetails',
                    value: { data : {
                        "user_id": res?.data?.id,
                        "userType": res?.data?.userType,
                        "reset": res?.data?.reset,
                        ...res?.data
                    }}
                });
                messageApi.open({
                    type: 'success',
                    content: res?.message,
                });

                let result = {
                    "message": res?.message,
                    "status": res?.status,
                    "data": {
                        "user_id": res?.data?.id,
                        "userType": res?.data?.userType,
                        "reset": res?.data?.reset,
                        ...res?.data
                    }
                }
                localStorage.setItem('userDetails', JSON.stringify(result));
                handleClose();
            }

            else if (res?.data?.reset) {
                setResetPassword(true)
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res?.message,
                });
            }
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const [formReset, setFormReset] = useState<ResetPassword>({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });



    const [loadingPassword, setLoadingPassword] = useState(false);
    const { oldPassword, newPassword, confirmPassword } = formReset;

    const [errorsPassword, setErrorsPassword] = useState<ResetPassword>({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const validateFormReset = (): boolean => {
        const newError = {
            oldPassword: oldPassword ? '' : 'Password is required',
            newPassword: newPassword ? '' : 'Password is required',
            confirmPassword: confirmPassword === newPassword ? '' : 'New and Confirm password must be same',
        }
        setErrorsPassword(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandlerPassword = (key: string) => (value: string) => {
        setFormReset({
            ...formReset,
            [key]: value,
        });
    };

    const handleClose = () => {
        setFormSignup({
            firstName: "",
            lastName: "",
            email: "",
            contact: "",
            signupPassword: "",
            // userType: ""
        })
        setErrorsSignup({
            firstName: "",
            lastName: "",
            email: "",
            contact: "",
            signupPassword: "",
            // userType: ""
        })
        setFormReset({
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        })
        setErrorsPassword({
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        })
        setForm({
            username: "",
            password: ""
        })

        setErrors({
            username: "",
            password: ""
        })
        setIsLogin(true)
        setResetPassword(false)
        setSuccesModel(false)
        onClose();
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );


    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        setFile(newFileList)
    }

    const handleFileChange = (e: any, name: any) => {
        const file = e.file;
        setFormSignup({
            ...formSignup,
            [name]: file,
        });
    };


    const getSHA256Hash = async (input: any) => {
        const textAsBuffer = new TextEncoder().encode(input);
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hash = hashArray
            .map((item) => item.toString(16).padStart(2, "0"))
            .join("");
        return hash;
    };

    const handleSavePassword = async () => {
        if (!validateFormReset()) return null;
        setLoadingPassword(true);
        let payload = {
            "endpoint": "/resetPassword",
            "data": {
                "username": username,
                "oldPasswd": await getSHA256Hash(oldPassword),
                "newPasswd": await getSHA256Hash(newPassword),
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));

        setLoadingPassword(false)
        if (res?.data?.updated) {
            dispatch({
                type: 'userDetails',
                value: tempLoginData
            });
            messageApi.open({
                type: 'success',
                content: res?.message,
            });
            localStorage.setItem('userDetails', JSON.stringify(tempLoginData));
            handleClose();
            setResetPassword(false)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }


    const userTypes = [
        {
            value: 'attendee',
            label: 'Attendee'
        },
        {
            value: 'sponsor',
            label: 'Sponsor'
        },
        {
            value: 'organiser',
            label: 'Organiser'
        }
    ]

    const onChangeToOrganiser: CheckboxProps['onChange'] = (e) => {
        setChecked(e.target.checked)
    };

    const [succmodelOpen, setSuccesModel] = useState(false)

    return (
        <>
            <Modal
                open={open}
                onCancel={handleClose}
                className='__common_model_class'
                footer={null}
                keyboard={false}
                closable={true}
                maskClosable={false}
            >
                {contextHolder}
                {!isResetPassword && !succmodelOpen && islogin && <>
                    <ModalWrap>
                        <h4 style={{ color: '#000' }}>Login</h4>
                        <InputField
                            error={errors.username}
                            onChange={onchangeHandler("username")}
                            value={username}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Username"
                            label="Username"
                            color="#000"
                        />

                        <InputField
                            error={errors.password}
                            onChange={onchangeHandler("password")}
                            value={password}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Password"
                            label="Password"
                            color="#000"
                            type="password"
                        />

                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '35px' }}>
                            <Button type="primary" label='Login' loading={loading} onClickHandler={() => handleSave()} width='120px' />
                        </div>

                        <DontAccount>Don’t have an account? <a onClick={() => setIsLogin(false)}>Signup</a></DontAccount>
                    </ModalWrap>
                </>}
                {!isResetPassword && !succmodelOpen && !islogin && <>
                    <ModalWrap>
                        <h4 style={{ color: '#000' }}>Signup</h4>
                        <InputField
                            error={errorsSignup.firstName}
                            onChange={onchangeHandlerSignup("firstName")}
                            value={firstName}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="First Name"
                            label="First Name"
                            color="#000"

                        />

                        <InputField
                            error={errorsSignup.lastName}
                            onChange={onchangeHandlerSignup("lastName")}
                            value={lastName}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Last Name"
                            label="Last Name"
                            color="#000"

                        />

                        <InputField
                            error={errorsSignup.email}
                            onChange={onchangeHandlerSignup("email")}
                            value={email}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Email"
                            label="Email"
                            color="#000"

                        />

                        <InputField
                            error={errorsSignup.signupPassword}
                            onChange={onchangeHandlerSignup("signupPassword")}
                            value={signupPassword}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Password"
                            label="Password"
                            color="#000"
                            type="password"
                        />

                        <InputField
                            error={errorsSignup.contact}
                            onChange={onchangeHandlerSignup("contact")}
                            value={contact}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Phone number"
                            label="Phone number"
                            color="#000"
                        />

                        <Checkbox className='___check_signup' style={{ display: 'flex' }} checked={checked} onChange={onChangeToOrganiser}><span className='__info'>I want to be an organiser<><Tooltip title={'Request for organiser subscription'}><InfoCircleFilled style={{ color: colors?.primary }} /></Tooltip></></span></Checkbox>

                        {/* <Select
                            onChangeHandler={onchangeHandlerSignup('userType')}
                            label="User Type"
                            options={userTypes}
                            value={userType}
                            error={errorsSignup.userType}
                        /> */}

                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '35px' }}>
                            <Button type="primary" label='Signup' loading={loadingSignup} onClickHandler={() => handleSaveSignup()} width='120px' />
                        </div>
                        <DontAccount>Already a member? <a onClick={() => setIsLogin(true)}>Login</a></DontAccount>
                    </ModalWrap>
                </>
                }

                {isResetPassword && !succmodelOpen && <>
                    <ModalWrap>
                        <h4 style={{ color: '#000' }}>Reset Password</h4>

                        <InputField
                            error={errorsPassword.oldPassword}
                            onChange={onchangeHandlerPassword("oldPassword")}
                            value={oldPassword}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Old Password"
                            label="Old Password"
                            color="#000"
                            type="password"
                        />

                        <InputField
                            error={errorsPassword.newPassword}
                            onChange={onchangeHandlerPassword("newPassword")}
                            value={newPassword}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="New Password"
                            label="New Password"
                            color="#000"
                            type="password"
                        />

                        <InputField
                            error={errorsPassword.confirmPassword}
                            onChange={onchangeHandlerPassword("confirmPassword")}
                            value={confirmPassword}
                            marginBottom={20}
                            marginTop={30}
                            placeholder="Confirm Password"
                            label="Confirm Password"
                            color="#000"
                            type="password"
                        />

                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '35px' }}>
                            <Button type="primary" label='Submit' width='120px' loading={loadingPassword} onClickHandler={() => handleSavePassword()} />
                        </div>
                    </ModalWrap>
                </>}

                {
                    !isResetPassword && !islogin && succmodelOpen && <>

                        <SuccessModalWrap>
                            <CheckCircleFilled style={{ color: '#11be02' }} />
                            <h3>Success</h3>
                            <h4>Congratulations<br />Your account has been created.</h4>
                            <p>Your request for Organiser Subscription under the review by the Convene Admin team. You will receive further updates via Email.</p>

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '35px', marginBottom: '10px' }}>
                                <Button type="primary" label='Continue' onClickHandler={() => handleClose()} width='100px' />
                            </div>
                        </SuccessModalWrap>
                    </>
                }

            </Modal>
        </>
    );
};

export default Login;

Login.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
