
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tabs, notification, Button, Menu, Dropdown, Tooltip, Popconfirm } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, EnvironmentOutlined, ExportOutlined, EyeOutlined, ImportOutlined, MailOutlined, MessageOutlined, PlusCircleFilled, SearchOutlined, ShareAltOutlined, SyncOutlined, UserAddOutlined } from '@ant-design/icons';
import AddConnection from 'modals/AddConnection';
import { ProfileInnerWrap, ConnectionSingle, ProfileWrap, EventItem, ProfileSettings, EventsListings, CampaignSingle } from './styles';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import AvatarImg from 'assets/images/avatar.webp';
import ImportConnections from 'modals/ImportConnections';
import Loader from 'components/Loader';

import EditEvent from 'modals/EditEvent';
import Dashboard from './Dashboard';
import InputField from 'components/InputField';
import ProfileView from 'modals/ProfileView';
import { colors } from 'assets/styles/colors';
import { useMediaQuery } from 'react-responsive';
import { usePapaParse } from 'react-papaparse';
import AddEditCampaign from 'modals/AddEditCampaign';
import moment from 'moment';

import { Breadcrumb, Layout, theme } from 'antd';
import type { MenuProps } from 'antd';
import { DashboardOutlined, NotificationOutlined, SettingOutlined, AreaChartOutlined, TeamOutlined } from '@ant-design/icons';


const { TabPane } = Tabs;
const { Header, Content, Footer, Sider } = Layout;

interface DropdownMenuProps {
    onEdit: () => void;
    onDelete: () => void;
}


interface DropdownMenuApprovalProps {
    onView: () => void;
    onMessage: () => void;
}

interface DropdownMenuCapmaignProps {
    onEdit: () => void;
    onDelete: () => void;
}



const TimeAgo = ({ timestamp }: any) => {
    const timeAgo = moment(timestamp).fromNow();
    return <span>{timeAgo}</span>;
};

const Profile: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = notification.useNotification();
    const { userDetails } = useSelector((store: any) => store.commonData);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' });

    const [loadingEvents, setLoadingEvents] = useState(false);
    const [events, setEvents] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [showAddConnection, setAddConnectionShow] = useState(false);
    const [showImportConnection, setImportConnectionShow] = useState(false);
    const [eventOpen, setEventEdit] = useState(false)
    const [profileOpen, setProfileView] = useState(false)
    const [userProfileData, setUserProfileData] = useState<any>('')
    const [isEdit, setEdit] = useState(false)
    const initialFormState = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        company: "",
        designation: "",
    }
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [form, setForm] = useState<any>(initialFormState);
    const { firstName, lastName, email, phone, location, company, designation, newPassword, confirmPassword } = form;
    const [errors, setErrors] = useState<any>(initialFormState);
    const [exportLoading, setExportLoading] = useState(false)
    const { readString } = usePapaParse();

    const [approvals, setApprovals] = useState<any>([])
    const [loadingApprovals, setLoadingApprovals] = useState(false);
    const [requstLoading, setRequestLoading] = useState<any>('')
    const [requestType, setRequestType] = useState<any>('')

    const [showAddCampaign, setAddCampaignShow] = useState(false);
    const [listCampaign, setListCampaign] = useState([])

    const [searchKey, setSearchConnectionKey] = useState('')
    const [isPublish, setIsPublish] = useState(0)

    const validateForm = (): boolean => {
        const newError = {
            firstName: firstName ? '' : 'First Name is required',
            lastName: lastName ? '' : 'Last Name is required',
            email: email ? '' : 'Email is required',
            phone: phone ? '' : 'Phone is required',
            location: '',
            company: '',
            designation: '',

        }
        setErrors(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };

    const openNotificationWithIcon = (type: 'success' | 'info' | 'warning' | 'error', title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };

    const handleEventClick = (eventId: string) => {
        navigate(`/event/${eventId}`);
    };

    const fetchEvents = async () => {
        const payload = {
            endpoint: '/searchEvents',
            "data": {
                "ismyevent": true,
                user_id: userDetails?.data?.user_id
            }
        };
        setLoadingEvents(true)
        const res = await fetchData(restAPIs.commonAPI(payload));
        setEvents(res?.data || []);
        setLoadingEvents(false)
    };


    const fetchUsers = async () => {
        setLoading(true);
        const payload:any = {
            endpoint: '/fetchConnections',
            data: {
                userId: userDetails?.data?.id,
            }
        };
        if(searchKey)
            payload.data.searchKey = searchKey
        const res = await fetchData(restAPIs.commonAPI(payload));
        setLoading(false);
        setUsers(res?.data?.contacts || []);
    };

    useEffect(() => {
        // if (userDetails) {
        fetchEvents();
        fetchUsers();
        fetchUserInfo();
        fetchApprovals();
        fetchCampaigns()
        // }
    }, []);

    const handleOpenConnection = (data: any) => {
        dispatch({
            type: 'connectionData',
            value: data
        });
        setAddConnectionShow(true);
    };

    const handleDeleteUser = async (connId: string) => {
        let payload = {
            endpoint: "/deleteContact",
            data: {
                connId: connId,
                userId: userDetails?.data?.id
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        if (res?.status === 'SUCCESS') {
            openNotificationWithIcon('success', 'Success', res?.message);
            setUsers(res?.data?.contacts || []);
        } else {
            openNotificationWithIcon('error', 'Error', res?.message);
        }
    };

    const handleEditEvent = (item: any) => {
        dispatch({
            type: 'eventData',
            value: {
                dataSet: item,
                fetchEvents
            }
        });
        setEventEdit(true)
    }

    const handlePublishEvent = async (item: any) => {
        let payload = {
            endpoint: "/publish_event",
            data: {
                eventId: item.id,
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        if (res?.status === 'SUCCESS') {
            openNotificationWithIcon('success', 'Success', res?.message);
            fetchEvents();
        } else {
            openNotificationWithIcon('error', 'Error', res?.message);
        }
    }

    const handleKeyDown = (event: any) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }

    const imageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUoAAAFKAQAAAABTUiuoAAACCElEQVR4nO2aQYrjMBBFX40FvVSgD9BHUa42R+ob2EfJDeRlQObPQpLbk56BZCCxYaogIVbe4kNRUumXTdwZ0497SXDUUUcdddTRZ6LWItRfTCew89yXz08X4OgjaJIkZZBUsPMcAAZJkn5HnyPA0UfQuZfQdAKNtC8zC68R4Oi/oOkSgFh2E+Do3yP8adHSJXxP1+5aHe1olDQCTB+lbYJn2jH2EgGO3hGttiYDYMDS5U2WPgFY7PkCHH0AtRvnyYgZA7i1pNx5OgpqdlrMznOomyBp08a3PfEwWv9nlH6jGkRSgZQHaYwFjVEi5YZo3F2roy0VyutCz1F/LO3u7NnaH+2FE9Vra01ezRFeW8dB+31rDkWTAZMNtWcX83uBmLE0Pk+Aow9nq3aCSUsQ8ztGLAHi1Uh5CTqIVke3XcZq42qMha8Gw8+to6BrPgrV0KiPeWjWRsprBnfX6ujGJxRzKNW9mD6uGxvDz62joL2D79HXWnfIZnfcXauj3cvos2NJkpm9CeYASVefHR8N/bplwWK1y2hDrsFnx4dB1/vWCdIn1dC1lJfQffnV391dq6Pf0bgeXhn009+iORB6OzsWs1FdDaLYDrh21+poR/vs2M5QJyZM1gcorxDg6D2xfQeNQRALzdXNg5ol7x38MdC6E37NiVU/UQCl/uFdhqOOOuqoo0dBfwGpqzxfkByqCgAAAABJRU5ErkJggg=='

    const handleShare = async () => {
        if (navigator.share) {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const file = new File([blob], 'image.jpg', { type: blob.type });

                await navigator.share({
                    title: 'imageTitle',
                    text: 'Check out this image!',
                    files: [file],
                });

            } catch (error) {
                console.error('Error sharing the image:', error);
            }
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    const fetchUserInfo = async () => {
        let payload = {
            endpoint: "/fetchUserInfo",
            data: {
                id: userDetails?.data?.user_id
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        if (res?.status === 'SUCCESS') {
            setUserProfileData(res?.data)
            setForm({ ...res?.data, phone: res?.data?.contact })

        } else {
            openNotificationWithIcon('error', 'Error', res?.message);
        }
    }

    const handleProfileView = (item: any) => {
        dispatch({
            type: 'profileItem',
            value: item
        });
        setProfileView(true)
    }

    const DropdownMenu: React.FC<DropdownMenuProps> = ({ onEdit, onDelete }) => {
        const menu = (
            <Menu>
                <Menu.Item key="3" icon={<EditOutlined />} onClick={onEdit}>
                    Edit
                </Menu.Item>
                <Menu.Item key="4" icon={<DeleteOutlined />} onClick={onDelete}>
                    Delete
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={menu} trigger={['click']}>
                <Button type="text" ghost style={{ border: '1px solid', minWidth: '32px' }} icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    };

    const DropdownMenuApproval: React.FC<DropdownMenuApprovalProps> = ({ onView, onMessage }) => {
        const menu = (
            <Menu>
                <Menu.Item key="3" onClick={onView}>
                    View Profile
                </Menu.Item>
                <Menu.Item key="4" onClick={onMessage}>
                    Message
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={menu} trigger={['click']}>
                <Button type="text" ghost style={{ border: '1px solid', minWidth: '32px' }} icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    };

    const DropdownMenuCampaign: React.FC<DropdownMenuCapmaignProps> = ({ onEdit, onDelete }) => {
        const menu = (
            <Menu>
                <Menu.Item key="1" icon={<EditOutlined />} onClick={onEdit}>
                    Edit
                </Menu.Item>
                <Menu.Item key="2" icon={<DeleteOutlined />} onClick={onDelete}>
                    Delete
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={menu} trigger={['click']}>
                <Button type="text" ghost style={{ border: '1px solid', minWidth: '32px' }} icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    };

    const handleUpdateSettingProfile = async () => {
        if (!validateForm()) return null;
        let payload = {
            endpoint: "/updateUserInfo",
            data: {
                userId: userDetails?.data?.user_id,
                userDetails: {
                    firstName,
                    lastName,
                    email,
                    contact: phone,
                    location,
                    company,
                    designation
                }
            }
        }

        setIsUpdateLoading(true)
        const res = await fetchData(restAPIs.commonAPI(payload));
        if (res?.status === 'SUCCESS') {
            setUserProfileData(res?.data?.userDetails[0])
            setForm({ ...res?.data?.userDetails[0], phone: res?.data?.userDetails[0].contact })
            setIsUpdateLoading(false)
            openNotificationWithIcon('success', 'Success', res?.message);

        } else {
            openNotificationWithIcon('error', 'Error', res?.message);
        }
    }

    const handleEditCancel = () => {
        setErrors(initialFormState)
        setEdit(false)
        setForm(
            {
                firstName: userProfileData?.firstName,
                lastName: userProfileData?.lastName,
                email: userProfileData?.email,
                phone: userProfileData?.contact,
                location: userProfileData?.location,
                company: userProfileData?.company,
                designation: userProfileData?.designation,
            }
        )
    }


    const handleExport = async () => {
        let payload = {
            endpoint: "/exportContacts",
            data: {
                userId: userDetails?.data?.user_id,
            }
        }

        setExportLoading(true)
        const res = await fetchData(restAPIs.commonAPI(payload));
        console.log("rs", res);
        setExportLoading(false)

        if (res?.status === 'SUCCESS') {
            const csvData = res?.data; // Assuming res?.data is a comma-separated string
            const blob = new Blob([csvData], { type: 'text/csv' }); // Create a Blob with the CSV string
            const url = window.URL.createObjectURL(blob); // Create a URL for the Blob
            const a = document.createElement('a'); // Create a new anchor element
            a.href = url; // Set the href attribute to the Blob URL
            a.download = 'data.csv'; // Set the download attribute to specify the file name
            document.body.appendChild(a); // Append the anchor element to the body
            a.click(); // Click the anchor element to trigger the download
            window.URL.revokeObjectURL(url); // Revoke the Blob URL to free up memory
            document.body.removeChild(a);
            openNotificationWithIcon('success', 'Success', res?.message);
        } else {
            openNotificationWithIcon('error', 'Error', res?.message);
        }
    }


    const fetchApprovals = async () => {
        setLoadingApprovals(true);
        const payload = {
            "endpoint": "/listOrganizerReq",
            "data":
            {
                "approvalStatus": "pending"
            }
        };
        const res = await fetchData(restAPIs.commonAPI(payload));
        setLoadingApprovals(false);
        setApprovals(res?.data || []);
    };

    const handleRequest = async (id: any, status: boolean) => {
        setRequestLoading(id);
        setRequestType(status ? 'accept' : 'reject')
        const payload = {
            "endpoint": "/organizerRequest",
            "data":
            {
                "userId": id,
                "isApproved": status
            }
        };
        const res = await fetchData(restAPIs.commonAPI(payload));
        setRequestLoading('');
        setRequestType('')
        fetchApprovals();
    }

    const formatDateRange = (startDate: string, endDate: string): string => {
        // Split the dates into their components
        const [startYear, startMonth, startDay] = (startDate?.split('-') || []).map(Number);
        const [endYear, endMonth, endDay] = (endDate?.split('-') || []).map(Number);

        // Check if dates are valid
        if (!startYear || !startMonth || !startDay || !endYear || !endMonth || !endDay) {
            throw new Error("Invalid date format");
        }

        // Create Date objects
        const startDateObj = new Date(startYear, startMonth - 1, startDay);
        const endDateObj = new Date(endYear, endMonth - 1, endDay);

        // Calculate the difference in days
        const diffTime = Math.abs(endDateObj.getTime() - startDateObj.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // Month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get month names
        const startMonthName = monthNames[startMonth - 1];
        const endMonthName = monthNames[endMonth - 1];

        // Format the result string
        return `${startDay} ${startMonthName} ${startYear} - ${endDay} ${endMonthName} ${endYear}`;
    };


    const fetchCampaigns = async () => {
        const payload = {
            endpoint:"/listCampaigns",
            data:{
                userId: userDetails?.data?.user_id,
            }
        };
        const res = await fetchData(restAPIs.commonAPI(payload));
        setListCampaign(res?.data?.campaigns || []);
    }

    const handleEditCampaign = (item:any)=>{

    }
    
    const handleDeleteCampaign = (item:any) =>{

    }
    const [selectedKey, setSelectedKey] = useState('1');
    const renderContent = () => {
        switch (selectedKey) {
          case '1':
            return <div> <Dashboard/> </div>;
          case '2':
            return <div>Content for Tab 2</div>;
          case 'connections':
            return <div className='container-fluid'>
            <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }} className='mt-3'>

                <div className='__search_sec'>
                    <input type='text' placeholder='Search...' value={searchKey} onChange={(e) => setSearchConnectionKey(e.target.value)} />
                    <Button icon={<SearchOutlined />} onClick={() => fetchUsers()}></Button>
                </div>
                <Tooltip title={'Add'}><Button icon={<UserAddOutlined />} onClick={() => setAddConnectionShow(true)}>{isTabletOrMobile ? "" : "Add"}</Button></Tooltip>
                <Tooltip title={'Import'}><Button icon={<ImportOutlined />} onClick={() => setImportConnectionShow(true)}>{isTabletOrMobile ? "" : "Import"}</Button></Tooltip>
                <Tooltip title={'Export'}><Button icon={<ExportOutlined />} onClick={() => handleExport()} loading={exportLoading}>{isTabletOrMobile ? "" : "Export"}</Button></Tooltip>
                <Tooltip title={'Sync'}><Button icon={<SyncOutlined />} onClick={() => fetchUsers()}></Button></Tooltip>
                <ImportConnections open={showImportConnection} onClose={() => setImportConnectionShow(false)} />
            </div>
            <AddConnection open={showAddConnection} onClose={() => setAddConnectionShow(false)} fetchUsers={() => fetchUsers()} />
            <div className='row mt-4'>
                {loading ? (
                    <div style={{ minHeight: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader size='default' />
                    </div>
                ) : (

                    <>
                        <div className={'col-md-12'}>
                            {users.length > 0 ? users.map((item: any) => (
                                <ConnectionSingle key={item.id}>
                                    <img src={item?.dp || AvatarImg} alt="" />
                                    <div>
                                        <h5>{item?.firstName} {item?.lastName}</h5>
                                        <p><a style={{ color: colors?.primary }} href={`mailto:${item?.email}`}>{item?.email}</a></p>
                                        <p><a style={{ color: colors?.primary }} href={`tel:${item?.countryCode}${item?.mobile}`}>{item?.countryCode} {item?.mobile}</a></p>
                                    </div>
                                    <div className="__buttons">
                                        <Button type='primary' icon={<MessageOutlined />}>Message</Button>
                                        <Button type='text' style={{ background: '#fff !important', border: '1px solid', color: colors?.primary }} ghost icon={<EyeOutlined />} onClick={() => handleProfileView(item)} >View Profile</Button>
                                        <DropdownMenu
                                            onEdit={() => handleOpenConnection(item)}
                                            onDelete={() => handleDeleteUser(item.id)}
                                        />
                                    </div>
                                </ConnectionSingle>
                            )) : "No Connections found.!"}
                        </div>
                    </>
                )}
            </div>
        </div>;
          case 'events':
            return <div><EventsListings>
            {loadingEvents ? (
                <div style={{ minHeight: '10vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader size='default' />
                </div>
            ) : (
                events?.length > 0 ? events.map((item: any, key: number) => (
                    <EventItem key={item.id} >
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <img src={item?.eventLogo} alt="" style={{ width: '60px' }} />
                            <div>
                                <h3>{item && formatDateRange(item?.startDate, item?.endDate)}</h3>
                                <h1 style={{ color: colors?.primary }} >{item?.title}</h1>
                                <span><EnvironmentOutlined /> {item?.location}</span>
                            </div>
                        </div>
                        <div className="__buttons">
                            <Button type='primary' icon={<EditOutlined />} onClick={() => handleEditEvent(item)}></Button>
                            {!item?.isPublished && <Button icon={<ArrowRightOutlined />} loading={isPublish === key} onClick={() => handlePublishEvent(item)}>Publish</Button>}
                            <Button icon={<EyeOutlined />} onClick={() => handleEventClick(item.id)}>View</Button>
                        </div>
                    </EventItem>
                )) : "No Events found.!")}
        </EventsListings>
        </div>;
          case 'settings':
            return <div><ProfileSettings>
            <div className='__head'>
                <div className='__round'>
                    {userProfileData && userProfileData?.firstName?.charAt(0)}
                </div>
                <div className='__share'>
                    <img src={`data:image/png;base64,${userProfileData?.qr_code}`} alt="" />

                    <Button style={{ border: '0', boxShadow: 'none' }} icon={<ShareAltOutlined />} onClick={() => handleShare()}>Share</Button>
                </div>
            </div>
            <div style={{ pointerEvents: isEdit ? 'auto' : 'none' }}>

                <InputField
                    error={errors.firstName}
                    onChange={onchangeHandler("firstName")}
                    value={firstName}
                    marginBottom={10}
                    placeholder="First Name"
                    label="First Name"
                    color="#000"
                />

                <InputField
                    error={errors.lastName}
                    onChange={onchangeHandler("lastName")}
                    value={lastName}
                    marginBottom={10}
                    placeholder="Last Name"
                    label="Lats Name"
                    color="#000"
                />

                <InputField
                    error={errors.email}
                    onChange={onchangeHandler("email")}
                    value={email}
                    marginBottom={10}
                    placeholder="Email"
                    label="Email"
                    color="#000"
                    isMandatory={true}
                />

                <InputField
                    onKeyDown={(e) => handleKeyDown(e)}
                    error={errors.phone}
                    onChange={onchangeHandler("phone")}
                    value={phone}
                    marginBottom={10}
                    isMandatory={true}
                    placeholder="Phone"
                    label="Phone"
                    color="#000"
                />

                <InputField
                    error={errors.newPassword}
                    onChange={onchangeHandler("newPassword")}
                    value={newPassword}
                    marginBottom={10}
                    placeholder="New Password"
                    label="Password"
                    color="#000"
                    type='password'

                />
                <InputField
                    error={errors.confirmPassword}
                    onChange={onchangeHandler("confirmPassword")}
                    value={confirmPassword}
                    marginBottom={10}
                    placeholder="Confirm Password"
                    label=" Password"
                    color="#000"
                    type='password'
                />

                <InputField
                    error={errors.location}
                    onChange={onchangeHandler("location")}
                    value={location}
                    marginBottom={10}
                    placeholder="Location"
                    label="Location"
                    color="#000"
                />

                <InputField
                    error={errors.company}
                    onChange={onchangeHandler("company")}
                    value={company}
                    marginBottom={10}
                    placeholder="Company"
                    label="Company"
                    color="#000"
                />
                <InputField
                    error={errors.designation}
                    onChange={onchangeHandler("designation")}
                    value={designation}
                    marginBottom={10}
                    placeholder="Designation"
                    label="Designation"
                    color="#000"
                />
            </div>
            <div className='__buttons'>
                {isEdit ? <>
                    <Button onClick={() => handleEditCancel()}>Cancel</Button>
                    <Button type='primary' onClick={() => handleUpdateSettingProfile()} loading={isUpdateLoading}>Update</Button>
                </> : <Button type='primary' onClick={() => setEdit(true)} icon={<EditOutlined />}>Edit</Button>}
            </div>
        </ProfileSettings>
        
        </div>;
          default:
            return <div>Select a tab to see content</div>;
        }
    };

    const handleMenuClick = (e: any) => {
    setSelectedKey(e.key); // Update the selected key
    };
    const items2 = [
        {
          key: 'dashboard',
        //   icon: <DashboardOutlined />,
          icon: <AreaChartOutlined />,
          label: 'Dashboard',
          children: [
            { key: 1, label: 'Registration Metrics' },
            { key: 2, label: 'Organizers Dashboard' }
          ],
        },
        {
          key: 'connections',
          icon:  <TeamOutlined />,
          label: 'Connections',
          
        },
        {
          key: 'events',
          icon: <NotificationOutlined />,
          label: 'My Events',
          
        },
        {
            key: 'settings',
            icon: <SettingOutlined />,
            label: 'Settings',
            
          },
      ];

    return (
        <ProfileWrap>
            {contextHolder}
            <ProfileInnerWrap>
                <Sider width={300} style={{ background: '#FFFFFF', color: '#ffffff' }}>
                    <Menu
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        onClick={handleMenuClick}
                        style={{ height: '100%', borderRight: 0 }}
                        items={items2}
                    />
                </Sider>
                <Layout style={{ flex: 1 }}> {/* Added Layout here to contain Content */}
                    <Content style={{ padding: '24px', minHeight: '100vh' }}>
                        {renderContent()} {/* Render content based on selected menu item */}
                    </Content>
                </Layout>
            </ProfileInnerWrap>
            
            <EditEvent open={eventOpen} onClose={() => setEventEdit(false)} />
            <ProfileView open={profileOpen} onClose={() => setProfileView(false)} />
        </ProfileWrap>
    );
};

export default Profile;
