
import styled from "styled-components";
// import Background from "assets/images/back_1.webp";
//  background-image: url(${Background});
export const BackgroundWrapper = styled.div`
    width:100%;
  `;

export const ContentWrapper = styled.div`
    display: flex;
    min-height:100vh;
    width:100%;
    overflow:auto;
    padding-top:60px;
  `;