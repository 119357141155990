const initialState = {
    userDetails: null,
    connectionData:null,
    eventData:null,
    profileItem:null,
    searchText:null,
    campaignData:null
};

export default initialState;
