import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {
  BackgroundWrapper, ContentWrapper
} from "./styles";

import Loader from 'components/Loader';
import Header from 'components/Header';
import CreateEvent from 'pages/CreateEvent';
import EventJoin from 'pages/EventJoin';
import EventDetail from 'pages/EventDetail';
import Search from 'pages/Search';
import Profile from 'pages/Profile';
import DynamicForm from 'pages/DynamicForm';

const HomePage = React.lazy(() => import('pages/Homepage'));
const MyEvents = React.lazy(() => import('pages/MyEvents'));

const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <div className='__dark'>
        <BackgroundWrapper>
          <Header />
          {/* <div className='container'> */}
          <ContentWrapper>
            <Suspense fallback={<Loader/>}>
              <Routes>
                <Route path="/home" element={<HomePage />} />
                <Route path="/my-events" element={<MyEvents />} />
                <Route path="/create-event" element={<CreateEvent />} />
                <Route path="/event/:eventId" element={<EventDetail />} />
                <Route path="/join-event/:eventId" element={<EventJoin />} />
                <Route path="/forms/:eventId" element={<DynamicForm />} />
                <Route path="/search" element={<Search />} />
                <Route path="/settings" element={<Profile />} />
                <Route path="*" element={<HomePage />} />
              </Routes>
            </Suspense>
            
          </ContentWrapper>
          {/* </div> */}
          </BackgroundWrapper>
        </div>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
