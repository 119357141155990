// Core
import React from 'react';
// Others
import AppRouter from 'routes';

// Styles
import { GlobalStyle } from 'assets/styles/globalStyles';
import './App.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'react-phone-input-2/lib/style.css'

export function App() {
  return (
      <>
      <GlobalStyle />
      <AppRouter />
      </>
  );
}

export default App;
