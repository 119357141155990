
import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const ModalWrap = styled.div`
    width:100%;
       
`

export const OTPTemplateChange = styled.div`
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
text-align:center;
z-index: 1111;
.__otp_input input {
    width: 40px !important;
    margin: 0 6px;
    height: 40px;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
}
`

export const DontAccount = styled.div`
margin-top: 35px;
    text-align: center;
    width: 100%;
    margin-bottom:15px;
    font-size:15px;
    a{
        color:${colors?.primary} !important;
        font-weight:bold ;
    }
`

export const CommonFieldWrap = styled.div`
    margin-bottom:20px;
        .__error{
            display: block;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 1px;
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
            font-size: 0.6rem;
            color: #FF4D4F;
        }
        label{
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 5px;
            margin-left: 0;
            font-size: 0.7rem;
            display:flex;
            align-items:center;
        }

        .__link_block{
            display:flex;
            gap:15px;
            align-items:flex-end;
            button:disabled{
                background:#ccc !important;
            }
            button{
                height:40px;
                display:flex;
                justify-content: center;
                align-items:center;
                color:#fff;
                background:#666 !important;
                label{
                    margin:0;
                }
            }
        }

        .ant-checkbox-wrapper .ant-checkbox{
            margin-top:0px;
        }
        .__auto_com_serch{
            border-width: 1.5px;
            border-radius: 6px;
            border-color: #E1E1E1 !important;
            font-size: 0.8rem;
            background-color: white;
            .ant-select-selector {
                border: 1px solid #d9d9d9 !important;
            }    
        }
`



export const SuccessModalWrap = styled.div`
    width:100%;
    font-size: 36px;
    text-align: center;
    h3{
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        color: #11be02;
    }
    h4{
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        margin-top:20px;
    }
    p{
        font-size:16px;
        margin-top:20px;
    }
    

`