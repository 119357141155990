/* eslint-disable react-hooks/exhaustive-deps */
// Core
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
    AddPost,
    CommonChartSec,
    EventBanner,
    EventDashboardGraphs,
    EventDetailWrapper,
    EventSponsor,
    EventTabSection,
    EventTitleSec,
    SinglePost,
    SingleReview
} from "./styles";

// Components
import Loader from "components/Loader";

// Others
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
// Components

import InputField from "components/InputField";
import CustomButton from "components/Button";
import { Button, QRCode, Rate, Tabs, message, notification } from 'antd';
import { CalendarOutlined, CheckOutlined, EditOutlined, EllipsisOutlined, EnvironmentFilled, EnvironmentOutlined, LeftOutlined, SaveOutlined, SearchOutlined, SendOutlined, ShareAltOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { colors } from "assets/styles/colors";

import ImageSample from 'assets/images/banking.png'

import FsLightbox from "fslightbox-react";
import Slider from "react-slick";
import Dashboard from "pages/Profile/Dashboard";


import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import funnelModule from "highcharts/modules/funnel";
import { useDispatch, useSelector } from "react-redux";
import Login from "modals/Login";
import EditEvent from "modals/EditEvent";
import AddEditPost from "modals/AddEditPost";
import AddEditReview from "modals/AddEditReview";

import moment from 'moment';


funnelModule(Highcharts);
// Types

const { TabPane } = Tabs

const TimeAgo = ({ timestamp }: any) => {
    const timeAgo = moment(timestamp).fromNow();
    return <span>{timeAgo}</span>;
};


function YouTubeVideo(videoId: any) {
    return (
        <iframe
            src={`https://www.youtube.com/embed/${videoId?.videoId}`}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    );
}
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const EventDetail: React.FC = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const { eventId } = useParams();
    const [data, setData] = useState<any>('')
    const [openLogin, setLoginOpen] = useState(false)

    const { userDetails } = useSelector((store: any) => store.commonData);
    const [registered, setEventReg] = useState(false)

    const [actionState, setActionState] = useState('requestStart')

    const dispatch = useDispatch();
    const [eventOpen, setEventEdit] = useState(false)
    const [postOpen, setPostOpen] = useState(false)
    const [feeds, setFeeds] = useState<any>([])
    const [reviewOpen, setReviewOpen] = useState(false)
    const [reviews, setReviews] = useState<any>([])


    const fetchEvents = async () => {
        setLoading(true)
        let payload = {
            "endpoint": "/getEventDetails",
            "data": {
                "eventId": eventId,
                "userId": userDetails?.data?.user_id
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        setData(res?.data);
        setLoading(false)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchEvents();
        getFeeds();
        getReviews();
    }, []);

    const [toggler, setToggler] = useState(false);

    const formatDateRange = (startDate: string, endDate: string): string => {
        // Split the dates into their components
        const [startYear, startMonth, startDay] = (startDate?.split('-') || []).map(Number);
        const [endYear, endMonth, endDay] = (endDate?.split('-') || []).map(Number);

        // Check if dates are valid
        if (!startYear || !startMonth || !startDay || !endYear || !endMonth || !endDay) {
            throw new Error("Invalid date format");
        }

        // Create Date objects
        const startDateObj = new Date(startYear, startMonth - 1, startDay);
        const endDateObj = new Date(endYear, endMonth - 1, endDay);

        // Calculate the difference in days
        const diffTime = Math.abs(endDateObj.getTime() - startDateObj.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // Month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get month names
        const startMonthName = monthNames[startMonth - 1];
        const endMonthName = monthNames[endMonth - 1];

        // Format the result string
        return `${startDay} ${startMonthName} ${startYear} - ${endDay} ${endMonthName} ${endYear}`;
    };

    const sources = [
        'https://i.imgur.com/fsyrScY.jpg',
        'https://www.youtube.com/watch?v=JixcNJZWXt8'
    ];


    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (index: any) => {
        setCurrentSlide(index);
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    };

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 0
    });

    const settings = {
        dots: false,
        infinite: false,
        arrow: false,
        speed: 500,
        slidesToShow: sources?.length ? sources?.length : 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1211,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: sources?.length - 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const options1 = {
        chart: {
            type: 'funnel',
            height: 350
        },
        title: {
            text: 'Sales funnel Chart'
        },
        colors: ['#ffc000', '#ed7d31', '#70ad47', '#5b9bd5', '#a4a4a4'],
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b> ({point.y:,.0f})',
                    align: 'left', // Align labels to the left
                    x: -220, // Adjust the x position to move labels outside the funnel
                    verticalAlign: 'middle',
                    connectorWidth: 0, // Remove the connector lines
                    softConnector: false,
                    connectorShape: 'crookedLine',
                },
                center: ['40%', '40%'],
                neckWidth: "0%",
                neckHeight: "0%",
                width: '40%',
                borderWidth: 5, // Add border to create gap effect
                borderColor: '#FFFFFF' // Color of the border, same as background for gap effect
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Unique users',
            data: [
                ['Leads', 320],
                ['Sales Calls', 240],
                ['Follow-up', 150],
                ['Conversion', 90],
                ['Sales', 40]
            ]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                inside: true
                            },
                            center: ['50%', '50%'],
                            width: '100%'
                        }
                    }
                }
            }]
        }
    };
    const options2 = {
        chart: {
            type: 'funnel',
            height: 350
        },
        title: {
            text: 'Recruitment Process'
        },
        colors: ['#ffc000', '#ed7d31', '#70ad47', '#5b9bd5', '#a4a4a4'],
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b> ({point.y:,.0f})',
                    align: 'left', // Align labels to the left
                    x: -220, // Adjust the x position to move labels outside the funnel
                    verticalAlign: 'middle',
                    connectorWidth: 0, // Remove the connector lines
                    softConnector: false,
                    connectorShape: 'crookedLine',
                    crookDistance: '10%' // Adjust the crook distance to ensure the labels align in a vertical line
                },
                center: ['40%', '40%'],
                neckWidth: "0%",
                neckHeight: "0%",
                width: '40%',
                borderWidth: 5, // Add border to create gap effect
                borderColor: '#FFFFFF' // Color of the border, same as background for gap effect
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Unique users',
            data: [
                ['Applied', 200],
                ['Passed Initial Round', 120],
                ['Received Callback', 80],
            ]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                inside: true
                            },
                            center: ['50%', '50%'],
                            width: '100%'
                        }
                    }
                }
            }]
        }
    };

    const options3 = {
        chart: {
            type: 'funnel',
            height: 350
        },
        title: {
            text: 'E-mail Campaign'
        },
        colors: ['#ffc000', '#ed7d31', '#70ad47', '#5b9bd5', '#a4a4a4'],
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b> ({point.y:,.0f})',
                    align: 'left', // Align labels to the left
                    x: -220, // Adjust the x position to move labels outside the funnel
                    verticalAlign: 'middle',
                    connectorWidth: 0, // Remove the connector lines
                    softConnector: false,
                    connectorShape: 'crookedLine',
                    crookDistance: '10%' // Adjust the crook distance to ensure the labels align in a vertical line
                },
                center: ['40%', '40%'],
                neckWidth: "0%",
                neckHeight: "0%",
                width: '40%',
                borderWidth: 5, // Add border to create gap effect
                borderColor: '#FFFFFF' // Color of the border, same as background for gap effect
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Unique users',
            data: [
                ['Delivered Meals', 100],
                ['Mail Opened', 80],
                ['Link Clicked', 40],
                ['Added Chart', 30],
                ['Sales', 25]
            ]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                inside: true,
                                connectorWidth: 0, // Remove the connector lines
                                softConnector: false,
                            },
                            center: ['50%', '50%'],
                            width: '100%'
                        }
                    }
                }
            }]
        }
    };

    const options4 = {
        chart: {
            type: 'funnel',
            backgroundColor: 'transparent',
            height: 350

        },
        title: {
            text: ''
        },
        colors: ['#ffc000', '#ed7d31', '#70ad47', '#5b9bd5', '#a4a4a4'],
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b> ({point.y:,.0f})',
                    align: 'left', // Align labels to the left
                    x: -220, // Adjust the x position to move labels outside the funnel
                    verticalAlign: 'middle',
                    connectorWidth: 0, // Remove the connector lines
                    softConnector: false,
                    connectorShape: 'crookedLine',
                    crookDistance: '10%' // Adjust the crook distance to ensure the labels align in a vertical line
                },
                center: ["40%", "40%"],
                neckWidth: "20%",
                neckHeight: "00%",
                width: "40%",
                borderWidth: "4px",
                backgroundColor: 'transparent',
                borderColor: '#f6f3f3' // Color of the border, same as background for gap effect
            }
        },
        legend: {
            enabled: false
        },
        series: [{
            name: 'Unique users',
            data: [
                ['Delivered Meals', 100],
                ['Mail Opened', 80],
                ['Link Clicked', 40],
                ['Added Chart', 30],
                ['Sales', 25]
            ]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 600
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                inside: true,
                                connectorWidth: 0, // Remove the connector lines
                                softConnector: false,
                            },
                            center: ['50%', '50%'],
                            width: '100%'
                        }
                    }
                }
            }]
        }
    };

    function adjustNeckWidth(chart: any) {
        chart.series[0].data.forEach((point: any) => {
            let value = point.y;
            let neckWidth;

            // Adjust the neck width based on the value
            if (value >= 80) {
                neckWidth = '40%';
            } else if (value >= 50) {
                neckWidth = '30%';
            } else {
                neckWidth = '20%';
            }

            point.update({
                neckWidth: neckWidth,
                neckHeight: `${100 / chart.series[0].data.length}%`
            }, false);
        });

        chart.redraw();
    }

    const option5 = {
        chart: {
            plotBackgroundColor: null,
            backgroundColor: 'transparent',
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 350
        },

        title: {
            text: '',
            align: 'left'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                cursor: 'pointer',
                colors: ['#041735', '#12345f', '#1f4c7c', '#3274b5', '#469ff8', '#73bff9'],
                borderRadius: 5,
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                    distance: -50,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        value: 4
                    }
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Distribution',
            data: [
                { name: 'USA', y: 11 },
                { name: 'India', y: 29 },
                { name: 'UAE', y: 35 },
                { name: 'Pitcairn Islands', y: 7 },
                { name: 'Japan', y: 8 },
                { name: 'Australia', y: 10 },
            ]
        }]
    }


    const handleEventRegistration = () => {
        if (userDetails) {
            setEventReg(true)
            openNotificationWithIcon('success', 'Success', 'Registration completed successfully.');
        }
        else {
            setLoginOpen(true)
        }
    }


    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const handleEditEvent = () => {
        dispatch({
            type: 'eventData',
            value: {
                dataSet: data,
                fetchEvents
            }
        });
        setEventEdit(true)
    }

    const handlePostAdd = () => {
        if (userDetails) {
            dispatch({
                type: 'feedData',
                value: null
            });
            setPostOpen(true)
        }
        else {
            setLoginOpen(true)
        }
    }

    const getFeeds = async () => {
        let payload = {
            "endpoint": "/listFeed",
            "data": {
                "eventId": eventId,
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        console.log("res?.data?.feeds", res?.data?.feeds);
        setFeeds(res?.data?.feeds)
    }

    const getReviews = async () => {
        let payload = {
            "endpoint": "/listReviews",
            "data": {
                "eventId": eventId,
            }
        }
        const res = await fetchData(restAPIs.commonAPI(payload));
        console.log("res?.data?.reviews", res?.data?.reviews);
        setReviews(res?.data?.reviews)
    }


    const handleReviewAdd = () => {
        if (userDetails) {
            dispatch({
                type: 'reviewData',
                value: null
            });
            setReviewOpen(true)
        }
        else {
            setLoginOpen(true)
        }
    }

    const formatDate = (dateStr: string) => {
        const dateObj = moment.utc(dateStr);
        const year = dateObj.format('YYYY')
        const monthName = dateObj.format('MMM');
        const day = dateObj.format('DD');
        const time = dateObj.format('h:mm A');
        return { year, month: monthName, day, time };
    };




    return (
        <>
            {loading ? <div style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Loader />
            </div> : <> <EventBanner>
                {data?.eventCover && (
                    <img
                        src={data?.eventCover}
                        alt="Event Cover"
                        onLoad={handleImageLoad}
                        style={{ display: imageLoaded ? 'block' : 'none' }}
                    />
                )}

                {/* <img src={data?.eventCover} alt="" /> */}
            </EventBanner>

                <EventDetailWrapper>
                    {contextHolder}
                    <EventTitleSec>
                        <div className="__top_left">
                            <div className="__img">
                                <img src={data?.eventLogo} alt="" />
                            </div>
                            <div className="__detail">
                                <div className="__det_top">
                                    <span>
                                        <a>Featured</a>
                                    </span>
                                    <span onClick={() => handleReviewAdd()}>
                                        <StarOutlined /> Add a review
                                    </span>
                                </div>
                                <h5>{data && formatDateRange(data?.startDate, data?.endDate)}</h5>
                                <h1>{data?.title}</h1>
                                <p className="__rating"><StarFilled /> {data?.averageRating} Ratings</p>
                                <p className="__location"><EnvironmentFilled /> {data?.location}</p>
                            </div>
                        </div>
                        <div className="__booth">
                            <div>
                                <div className="__qr_code" style={{ width: '100px', marginBottom: '10px' }}>
                                    {data?.qr_code && <img src={`data:image/png;base64,${data?.qr_code}`} alt="" />}
                                </div>
                            </div>
                            {data && data?.role !== 'organizer' &&
                                <div className="__buttons">

                                    {registered ? <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }}>Registered <CheckOutlined /></button> : <button onClick={() => handleEventRegistration()}>Register Event</button>}

                                    {data?.isSponsorshipEnabled && <>
                                        {actionState === 'requestStart' && <button style={{ backgroundColor: colors?.primary, color: '#fff', flex: 1 }} onClick={() => navigate('/join-event/' + eventId)}>Request to Sponsor</button>}
                                        {actionState === 'requestSent' && <button style={{ backgroundColor: '#d17502', color: '#fff', flex: 1, display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center' }} onClick={() => navigate('/join-event/' + eventId)}>Request Sent <SendOutlined /> </button>}
                                        {actionState === 'requestApproved' && <button style={{ backgroundColor: '#22b141', color: '#fff', flex: 1, display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center' }} onClick={() => navigate('/join-event/' + eventId)}>Approved <CheckOutlined /> </button>}
                                    </>}
                                </div>
                            }

                            {data && data?.role === 'organizer' && <div className="__buttons" style={{ justifyContent: 'end' }}>
                                <Button type='primary' icon={<EditOutlined />} onClick={() => handleEditEvent()} style={{ minWidth: '80px', display: 'flex', alignItems: 'center' }}>Edit</Button>
                            </div>}
                        </div>
                    </EventTitleSec>

                    <EventTabSection>
                        <FsLightbox
                            sources={
                                data?.videoLinks
                            }
                            toggler={lightboxController.toggler}
                        />
                        <Tabs defaultActiveKey="1" >

                            <TabPane
                                tab={'About'}
                                key={'1'}
                            >
                                <div className="__slider_sec">
                                    <Slider {...settings} initialSlide={currentSlide}>
                                        {data?.videoLinks?.map((source: any, index: any) => (
                                            <>
                                                {source.includes('youtube') ? (
                                                    <div onClick={() => handleSlideChange(index)} className="__div">
                                                        <YouTubeVideo videoId={source?.split('v=')[1]} />
                                                    </div>
                                                ) : (
                                                    <img src={source} alt={`Image ${index}`} key={index} onClick={() => handleSlideChange(index)} />
                                                )}
                                            </>
                                        ))}
                                    </Slider>
                                </div>
                                <p>{data?.desc}</p>

                                <div className="__hightlights">
                                    <h5>Highlights</h5>
                                    <ul>
                                        <li dangerouslySetInnerHTML={{ __html: data?.highlights }}></li>
                                    </ul>
                                </div>

                                <div className="__tags">
                                    <h5>Listed In</h5>
                                    <span>
                                        {
                                            data && data.tags && data.tags.length > 0 && data.tags.map((item: any, index: any) => (
                                                <div key={index}>#{item}</div>
                                            ))
                                        }
                                    </span>
                                </div>

                            </TabPane>

                            <TabPane
                                tab={'Feed'}
                                key={'2'}
                            >
                                <div className="row">
                                    <div className="col-md-8 mb-3">
                                        <div className="__feed">

                                            <AddPost onClick={() => handlePostAdd()}>
                                                <img src={data?.eventLogo} alt="" />
                                                <input type="text" placeholder="Write a post here..." />
                                            </AddPost>

                                            {feeds?.map((item: any, idx: any) => {
                                                return (
                                                    <SinglePost key={idx}>
                                                        <div className="__top_head">
                                                            <img src={item?.dp} alt="" />
                                                            <div>
                                                                <h1>{item?.username}</h1>
                                                                <TimeAgo timestamp={item?.date} />
                                                            </div>
                                                            <div className="__three_dot"><EllipsisOutlined /></div>
                                                        </div>
                                                        <p>{item?.comment}</p>

                                                        <div className="__tags">
                                                            {item?.tags && item?.tags?.map((itemB: any, idx2: any) => {
                                                                return (
                                                                    <a key={idx2}>#{itemB}</a>
                                                                )
                                                            })}
                                                        </div>
                                                    </SinglePost>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                </div>

                            </TabPane>

                            <TabPane
                                tab={'Reviews'}
                                key={'3'}
                            >
                                <div className="row">
                                    <div className="col-md-8 mb-3">
                                        <div className="__feed">

                                            {reviews?.map((item: any, idx: any) => {
                                                return (
                                                    <SingleReview key={idx}>
                                                        <div className="__top_head">
                                                            <img src={item?.dp} alt="" />
                                                            <div>
                                                                <h1>{item?.firstName}</h1>
                                                                <TimeAgo timestamp={item?.timestamp} />
                                                            </div>
                                                            <div className="__three_dot"><EllipsisOutlined /></div>
                                                        </div>
                                                        <span><Rate value={item?.ratingValue} disabled /></span>
                                                        <p>{item?.comment}</p>
                                                    </SingleReview>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                </div>

                            </TabPane>

                            <TabPane
                                tab={'Dashboard'}
                                key={'4'}>
                                {/* <Dashboard /> */}

                                {/* {userDetails?.data?.userType !== 'sponsor' && <EventDashboardGraphs>
                                    <div className="__single">
                                        <HighchartsReact highcharts={Highcharts} options={options1} />
                                    </div>
                                    <div className="__single">
                                        <HighchartsReact highcharts={Highcharts} options={options2} />
                                    </div>
                                    <div className="__single">
                                        <HighchartsReact highcharts={Highcharts} options={options3} />
                                    </div>
                                </EventDashboardGraphs>
                                } */}

                                {
                                    <EventSponsor>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CommonChartSec>
                                                    <h3>Win %</h3>
                                                    <div className="__content_sec_ev">
                                                        <h1>16.53%</h1>
                                                        <span>as of 05-30-2024</span>
                                                    </div>
                                                </CommonChartSec>
                                            </div>
                                            <div className="col-md-3">
                                                <CommonChartSec>
                                                    <h3>Total Visitors</h3>
                                                    <div className="__content_sec_ev">
                                                        <h1>750</h1>
                                                        <span>as of 05-30-2024</span>
                                                    </div>
                                                </CommonChartSec>
                                            </div>
                                            <div className="col-md-6">

                                            </div>
                                            <div className="col-md-5">
                                                <CommonChartSec>
                                                    <h3>Sales Funnel</h3>
                                                    <div className="__content_sec_ev">
                                                        <HighchartsReact highcharts={Highcharts} options={options4} />
                                                    </div>
                                                </CommonChartSec>
                                            </div>
                                            <div className="col-md-7">
                                                <CommonChartSec>
                                                    <h3>Geo Distribution</h3>
                                                    <div className="__content_sec_ev" >
                                                        <HighchartsReact highcharts={Highcharts} options={option5} style={{ height: '300px' }} />
                                                    </div>
                                                </CommonChartSec>
                                            </div>
                                        </div>

                                    </EventSponsor>
                                }
                            </TabPane>
                        </Tabs>
                    </EventTabSection>
                    <Login open={openLogin} onClose={() => setLoginOpen(false)} />
                    <EditEvent open={eventOpen} onClose={() => setEventEdit(false)} />

                    <AddEditPost
                        open={postOpen}
                        onClose={() => setPostOpen(false)}
                        eventId={eventId}
                        getFeeds={getFeeds}
                    />
                    <AddEditReview
                        open={reviewOpen}
                        onClose={() => setReviewOpen(false)}
                        eventId={eventId}
                        getReviews={getReviews}
                    />
                </EventDetailWrapper></>}
        </>
    );
};

export default EventDetail;