
// Core
import React, { useCallback, useEffect, useState } from 'react';
// Assets
import LogoImg from 'assets/images/logo.png';
// Styles
import { HeaderOuter, Logo, RightMenu, SearchSection } from './styles';
import { LoginOutlined, PlusOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Avatar, Dropdown, MenuProps, Tooltip, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Login from 'modals/Login';
import { updatePrimaryColor } from 'assets/styles/colors';
import { debounce } from 'lodash';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const Header: React.FC = () => {
    const [openLogin, setLoginOpen] = useState(false);
    const navigate = useNavigate();
    const { userDetails } = useSelector((store: any) => store.commonData);
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState<any>('');

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' });
    const location = useLocation();
    const noHeader = location.pathname.includes('forms');
    const [messageApi, contextHolder] = notification.useNotification();

    const handleLogout = () => {
        dispatch({
            type: 'userDetails',
            value: null,
        });
        localStorage.setItem('userDetails', '');
        localStorage.setItem('sessiontoken', '');
        updatePrimaryColor('#ff8a00');
        navigate('/'); // Navigate to home or login page after logout
    };

    const handleSettings = () => {
        // Navigate to settings page or handle settings logic here
        navigate('/settings');
    };

    const items: MenuProps['items'] = [
        // ...(userDetails?.data?.userType !== 'attendee' ? [{ label: 'Settings', key: 'settings', onClick: handleSettings }] : []),
        
        { label: `Hi ${userDetails?.data?.fullName}`, type:'group' ,key: '1'},
        { type:'divider'},
        { label: 'Settings', key: 'settings', onClick: handleSettings },
        { label: 'Log Out', key: 'logout', onClick: handleLogout },
    ];

    useEffect(() => {
        const localDetails = localStorage.getItem("userDetails");
        if (!userDetails && localDetails) {
            dispatch({
                type: "userDetails",
                value: JSON.parse(localDetails),
            });
            if (JSON.parse(localDetails)?.data?.userType == 'sponsor') {
                updatePrimaryColor('#8913ff');
            }
            if (JSON.parse(localDetails)?.data?.userType == 'organizer') {
                updatePrimaryColor('#469ff8');
            }
        }
    }, []);

    const debouncedDispatch = useCallback(
        debounce((value) => {
            dispatch({
                type: "searchText",
                value,
            });
        }, 1500),
        []
    );


 const handleSearchValueChange = (e: any) => {
        const value = e.target.value;
        setSearchValue(value);
        debouncedDispatch(value);
    };

    const handleSearch = () => {
        navigate('/search?searchText=' + encodeURIComponent(searchValue));
    };


    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
      };


    const handleAddEvent = () => {
        if (userDetails) {
            if (userDetails?.data?.userType === 'organizer')
                navigate('/create-event')
            else {
                let msg = `You do not have enough privileges to create an Event. Please contact Convene support to upgrade to Organizer`
                openNotificationWithIcon('warning', 'Info', msg)
            }
        }

        else {
            setLoginOpen(true)
        }
    }

    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };

    if (noHeader) return null;
    return (
        <HeaderOuter borderColor={(userDetails?.data?.userType === 'sponsor') ? '#8913ff' : (userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00')}>
            <Logo onClick={() => navigate('/')}>
                <img src={LogoImg} alt="Convene360" />
                {!isTabletOrMobile &&
                    <div style={{ position: 'relative' }}>
                        <span>Convene360</span>
                        {userDetails?.data?.userType === 'sponsor' && <button className='___spnsr_bn'>SPONSOR</button>}
                        {userDetails?.data?.userType === 'organizer' && <button className='___spnsr_bn' style={{ background: '#469ff8' }}>ORGANISER</button>}
                    </div>}
            </Logo>
            {!isTabletOrMobile && (
                <>
                    <SearchSection>
                        <input
                            type="text"
                            placeholder="Search for Topic, Event, Category or Location"
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onKeyDown={handleKeyDown}
                        />
                        <button onClick={handleSearch}><SearchOutlined /></button>
                    </SearchSection>
                    <RightMenu backgroundColor={(userDetails?.data?.userType === 'sponsor') ? '#8913ff' : (userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00')}>
                        <a onClick={() => handleAddEvent()}><PlusOutlined /> Add Events</a>
                        {userDetails?.data ? (
                            <Dropdown menu={{ items }} trigger={['click']}>
                                <Avatar className='__bal' style={{ border: '2px solid', cursor:'pointer', borderColor: (userDetails?.data?.userType === 'sponsor') ? '#8913ff' : (userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00'),  backgroundColor: (userDetails?.data?.userType === 'sponsor') ? '#8913ff' : (userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00'), fontSize:'17px' }} src={userDetails?.data?.dp}>{userDetails?.data?.fullName?.slice(0, 1)}</Avatar>
                            </Dropdown>
                        ) : (
                            <button onClick={() => setLoginOpen(true)}><LoginOutlined />Login</button>
                        )}
                    </RightMenu>
                </>
            )}

            {isTabletOrMobile && (
                <>
                    <SearchSection>
                        <input type="text" placeholder="Search for Topic, Event, Category or Location" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={handleKeyDown}/>
                        <button onClick={handleSearch}><SearchOutlined /></button>
                    </SearchSection>
                    <RightMenu backgroundColor={(userDetails?.data?.userType === 'sponsor') ? '#8913ff' : (userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00')}>
                        <Tooltip title="Create Event"><a onClick={() => handleAddEvent()}><PlusOutlined /></a></Tooltip>
                        <Tooltip title="Login">
                            {userDetails?.data ? (
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <Avatar className='__bal' style={{ border: '2px solid', cursor:'pointer', borderColor: (userDetails?.data?.userType === 'sponsor') ? '#8913ff' : (userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00'),  backgroundColor: (userDetails?.data?.userType === 'sponsor') ? '#8913ff' : (userDetails?.data?.userType === 'organizer' ? '#469ff8' : '#ff8a00'), fontSize:'17px' }} src={userDetails?.data?.dp}>{userDetails?.data?.fullName?.slice(0, 1)}</Avatar>
                                </Dropdown>
                            ) : (
                                <button onClick={() => setLoginOpen(true)}><LoginOutlined /></button>
                            )}
                        </Tooltip>
                    </RightMenu>
                </>
            )}
            {contextHolder}
            <Login open={openLogin} onClose={() => setLoginOpen(false)} />
        </HeaderOuter>
    );
};

export default Header;
