// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.css';
import { Modal, Upload, notification, message, Image, Tag, Rate } from 'antd';
import { CommonFieldWrap, ModalWrap } from './styles';
import InputField from 'components/InputField';
import Button from 'components/Button';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Textarea from 'components/TextArea';
import { colors } from 'assets/styles/colors';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const AddEditReview = (props: any) => {
    const { onClose, open, eventId, getReviews } = props;
    const { userDetails, reviewData } = useSelector((store: any) => store.commonData);
    const [messageApi, contextHolder] = notification.useNotification();
    const [rate, setRate] = useState<number>(0);

    const dispatch = useDispatch();

    const initialFormState = {
        comment: ""
    }

    const [form, setForm] = useState<any>(initialFormState);
    const [loading, setLoading] = useState(false);
    const { comment } = form;
    const [errors, setErrors] = useState<any>(initialFormState);

    useEffect(() => {
        if (reviewData) {
            setForm(reviewData);
        } else {
            setForm(initialFormState);
        }
    }, [reviewData]);

    const validateForm = (): boolean => {
        const newError = {
            comment: comment ? '' : 'Comments is required',

        }
        setErrors(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };


    const handleClose = () => {
        setForm(initialFormState)
        setErrors(initialFormState)
        setRate(0)
        onClose();
        dispatch({
            type: 'reviewData',
            value: null
        });

    }

    const handleSave = async () => {
        if (!validateForm()) return null;
        const payload: any = {
            endpoint: "/addReview",
            data: {
                ratingValue: rate,
                comment,
                eventId: eventId,
                userId: userDetails?.data?.user_id
            }
        };

        if (reviewData) {
            payload.data.feedId = reviewData.id;
        }

        setLoading(true);
        const res = await fetchData(restAPIs.commonAPI(payload));

        if (res?.status === 'SUCCESS') {
            openNotificationWithIcon('success', 'Success', res?.message)
            getReviews();
            handleClose();
        }
        else {
            openNotificationWithIcon('error', 'Error', res?.message)
        }
        setLoading(false);

    }


    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };

    const onchangeRate = (value: number) => {
        setRate(value);
    };





    return (
        <>
            <Modal
                open={open}
                onCancel={handleClose}
                className='__common_model_class ___add_Post'
                footer={null}
                keyboard={false}
                closable={true}
                maskClosable={false}
            >
                {contextHolder}
                <ModalWrap>
                    <h4 style={{ color: '#000' }}>{reviewData ? "Edit" : "Add"} Review</h4>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginBottom: '10px' }}>
                        <label htmlFor="rate">Rating</label>
                        <Rate value={rate} onChange={onchangeRate} style={{ color: colors?.primary }} />
                    </div>

                    <Textarea
                        label="Comments"
                        placeholder="Comments"
                        value={comment}
                        onChange={onchangeHandler('comment')}
                        error={errors.comments}
                        height={250}
                        color="#222"
                    />
                    {/* 
                    <CommonFieldWrap>
                        <div className='__link_block'>
                            <InputField
                                onChange={onchangeHandlerTag}
                                value={tag}
                                placeholder="Tag"
                                label="Tags"
                                color="#222"

                            />
                            <Button type="primary" disabled={!tag} label='Add' onClickHandler={() => handleTagAdd()} />
                        </div>

                        {tags?.length > 0 && <div style={{ marginTop: '10px' }}>
                            {tags && tags.map((item: any, idx: any) => {
                                return (
                                    <Tag closeIcon={<CloseCircleOutlined />}
                                        onClose={(e) => {
                                            e.preventDefault();
                                            removeTag(item, idx);
                                        }}
                                        key={idx}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        {item}
                                    </Tag>
                                )
                            })}

                        </div>}
                    </CommonFieldWrap> */}

                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '35px' }}>
                        <Button type="primary" label='Submit' loading={loading} onClickHandler={() => handleSave()} width='120px' />
                    </div>
                </ModalWrap>
            </Modal>
        </>
    );
};

export default AddEditReview;

AddEditReview.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    eventId: PropTypes.string,
    getReviews: PropTypes.func
};
