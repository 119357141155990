import styled, { createGlobalStyle, css } from "styled-components";

// Types
import { FontSizes, FontWeight } from "utils/enums";
import { colors } from "./colors";
import { LabelProps } from "./types";

export const GlobalStyle = createGlobalStyle`

.ant-modal
.highcharts-credits{
  display:none;
}

    a {
        color: ${colors.primary};
        text-decoration: none;
        cursor: pointer;
    }
    
    * {
        box-sizing: border-box;
        
    }

    body {
      font-family: "Inter", sans-serif;
        margin: 0;
        padding: 0;
        font-size: 16px;
    }

    img {
        max-width: 100%;
        vertical-align: middle;
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 12px;

      span {
        font-size: ${FontSizes.input}rem;

        small {
          color: ${colors.secondaryText}
        }
      }
    }

    .password-indicator {
      margin-top: 10px;
      margin-bottom: 40px;
      & > div:first-child {
         div {
            height: 3px !important;
            border-radius: 2px;
         }
      }

      p {
        margin-right: 10px !important;
        color: ${colors.primaryText} !important;
        font-size: ${FontSizes.error}rem !important;
        text-align: left !important;
      }
    }

    .no-panel-collapse {
      border: none;
      background-color: transparent !important;

      .ant-collapse-header {
        display: none !important;
      }

      .ant-collapse-item {
        border: none !important;
      }

      .ant-collapse-content {
        border: none !important;
        background-color: transparent;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 10px 15px;
        cursor: pointer;
        .ant-tabs-tab-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: ${colors.secondaryText};

          .anticon {
            margin: 0;
            margin-bottom: 5px;
            font-size: ${FontSizes.sidebarIcon}rem;
          }
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn { 
          color: ${colors.primary};
        }
      }

      .ant-tabs-ink-bar {
        background-color: ${colors.primary}
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin-left: 15px;
      }
    }

    .ant-modal { 
      .ant-modal-content {
        padding: 0;
      }

      .ant-modal-header, .ant-modal-body, .ant-modal-footer {
        padding: 15px 25px;
      }

      .ant-modal-header {
        padding-top: 25px;
        border-bottom: 1px solid ${colors.border};
      }

      .ant-modal-footer {
        padding-bottom: 25px;
        border-top: 1px solid ${colors.border};

        .ant-btn-dashed {
          border: none !important;
          box-shadow: none;
        }
      }

      .ant-modal-body {
      h4{
        font-size: 17px;
        margin: 0;
        margin-top: 5px;
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
      }
      }
    }

    .confirm-modal {
      .ant-modal-content {
        padding: 20px;
      }

      .ant-modal-confirm-btns {
        margin-top: 20px;
      }
    }

    .ant-btn-primary {
      background: ${colors.primary};
    }
    
    .ant-btn-default {

      &:hover {
        color: ${colors.primaryText} !important;
        border-color: ${colors.grey5} !important;
      }
    }

    .ant-modal-confirm-btns {
      .ant-btn {
      font-size: ${FontSizes.button}rem !important;
      }
    }

    .ant-btn-sm {
    font-size: ${FontSizes.button}rem !important;
    }

    .ant-picker .ant-picker-input >input {
      font-size: ${FontSizes.input}rem;
    }

    .ant-dropdown-menu-title-content {
      font-size: ${FontSizes.floatingLabel}rem;
    }
    .ant-breadcrumb {
      font-size: ${FontSizes.h4}rem;

      li {
        &:last-child {
          font-weight: ${FontWeight.medium};
        }
      }
    }

    .ant-layout {
      background-color: white;
    }

    .ant-layout-content {
      background-color: ${colors.grey3};
      padding: 20px 20px 30px;
    }

    .ant-layout-sider {
      // background-color: ${colors.darkPrimary2} !important;
    }
    .ant-switch.ant-switch-checked {
      background-color: ${colors.primary};
    }

    .ant-btn-dashed {
      background: white !important;
      color: ${colors.primaryText} !important;
      border: 1.5px solid ${colors.blue1} !important;
    }

    .ant-radio-group {
      background-color: ${colors.blue1};
      border-radius: 20px;

      .ant-radio-button-wrapper:not(:first-child)::before {
        content: none;
       }

      .ant-radio-button-wrapper {
        line-height: 25px;
        height: 25px;
        padding: 0 25px;
        color: ${colors.secondaryText};
      }

      .ant-radio-button-wrapper-checked {
        background-color: ${colors.primary} !important;
      }

      * {
        border: none;
      }
      .ant-radio-button-wrapper {
        border-radius: 20px;
        background-color: ${colors.blue1};
        font-size: ${FontSizes.floatingLabel}rem;

        &:first-child {
          border-inline-start: none
        }
      }
    }

    .ant-spin-fullscreen {
      background-color: white;
    }

    .ant-spin-fullscreen .ant-spin-dot .ant-spin-dot-item {
    background-color: ${colors.primary};
    }

    .ant-btn-primary:disabled {
      background-color: ${colors.primary};
      color: white;
    }

    .ant-picker {
      border: 1.5px solid ${colors.grey2};
      border-radius: 6px;
      font-size: 0.8rem;
      height: 40px;
      width: 100%;

      input::placeholder {
      font-size: ${FontSizes.floatingLabel}rem;
      color: ${colors.grey1};
      }
    }

    .ant-picker:hover, .ant-picker-focused.ant-picker {
      border-color: ${colors.grey2};
     }

    .ant-checkbox-wrapper {
        text-align: left;
        line-height: 1.1rem;
        .ant-checkbox {
            align-self: flex-start;
            margin-top: 4px;
        }

        .ant-checkbox-inner {
            border-color: ${colors.primary};
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${colors.primary};       
            &:hover {
              background-color: ${colors.primary} !important;
            }
        }
    }

    .checkbox-error {
      .ant-checkbox-inner {
            border-color: ${colors.error};
        }
    }

    .ant-tooltip-inner {
      max-height: 200px;
      overflow-y: auto;
  }
`;



export const Header = styled.div`
    border-bottom: 1px solid ${colors.grey2};
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    background-color: white;
`;

export const Label = styled.label<LabelProps>`
  display: block;
  padding-top: ${({ paddingTop }: {paddingTop?: number}) => (paddingTop ? `${paddingTop}px` : "0")};
  padding-right: ${({ paddingRight }: {paddingRight?: number}) =>
    paddingRight ? `${paddingRight}px` : "0"};
  padding-bottom: ${({ paddingBottom }: {paddingBottom?: number}) =>
    paddingBottom ? `${paddingBottom}px` : "0"};
  padding-left: ${({ paddingLeft }: {paddingLeft?: number}) =>
    paddingLeft ? `${paddingLeft}px` : "0"};
  margin-top: ${({ marginTop }: {marginTop?: number}) => (marginTop ? `${marginTop}px` : "0")};
  margin-right: ${({ marginRight }: {marginRight?: number}) =>
    marginRight ? `${marginRight}px` : "0"};
  margin-bottom: ${({ marginBottom }: {marginBottom?: number}) =>
    marginBottom ? `${marginBottom}px` : "0"};
  margin-left: ${({ marginLeft }: {marginLeft?: number}) => (marginLeft ? `${marginLeft}px` : "0")};
  font-size: ${({ fontSize }: {fontSize?: number}) =>
    `${fontSize ? fontSize : FontSizes.default}rem`};
  color: ${({ color }: {color?: string}) => color || "inherit"};

  ${({ pointer }: {pointer?: boolean}) => css`
    ${pointer &&
    css`
        cursor: pointer;
      `}
  `}

  span {
    color: ${colors.error};
  }
`;


export const PinInput = styled.div`
  color: ${colors.grey2};
  position: relative;
  ${Label} {
    text-align: left;
  }
  input {
    font-size: 18px;
    flex: 1;
    border: 1.5px solid ${colors.grey2};
    border-radius: 6px;
    height: 40px;
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const InlineButton = styled.span`
  cursor: pointer;
  color: ${colors.primary} !important;
  text-decoration: underline;
`;


export const H1Head = styled.h1`
    font-size:${FontSizes?.h1}rem;
    margin:0;
    font-weight:700;
    width:max-content;
`

export const CommonGlassContainer = styled.div`
    padding: 24px;
    position: relative;
    border-radius: 20px;
    background: #ffffff61;
    color: #1e0153;
    font-size:${FontSizes?.default}rem;
    margin-bottom: 20px;
    border: 1px solid #fff3;
    p{
      margin:0
    }
`
// backdrop-filter: blur(5px);