// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.css';
import { Modal, Upload, notification, Button as ButtonANTD } from 'antd';
import { CommonFieldWrap, ModalWrap } from './styles';
import Button from 'components/Button';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import Textarea from 'components/TextArea';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });


const ImportConnections = (props: any) => {
    const { onClose, open, fetchUsers } = props;
    const { userDetails } = useSelector((store: any) => store.commonData);
    const [messageApi, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const [description, setDiscription] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [base64File, setBase64File] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);

    const onchangeHandler = (key: string) => (value: string) => {
        setDiscription(value)
    };

    const handleClose = () => {
        setFileList([])
        setDiscription('')
        onClose();
    }

    const handleSave = async () => {
        let payload = {
            'endpoint': "/importConnections",
            'data': {
                "userId": userDetails?.data?.user_id,
                "csv_file": base64File
            }


        }
        setLoading(true);
        const res = await fetchData(restAPIs.commonAPI(payload));

        if (res?.status === 'SUCCESS') {
            openNotificationWithIcon('success', 'Success', res?.message)
            fetchUsers();
            handleClose();
        }
        else {
            openNotificationWithIcon('error', 'Error', res?.message)
        }
        setLoading(false);

    }

    const handleKeyDown = (event: any) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }


    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        messageApi[type]({
            message: title,
            description: message,
        });
    };

    const handleFileChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        setFileList(newFileList);

        if (newFileList.length > 0) {
            const file = newFileList[0].originFileObj;
            if (file) {
                setFileName(file.name);
                const base64 = await getBase64(file);
                setBase64File(base64);
            }
        } else {
            setFileName(null);
            setBase64File(null);
        }
    }



    return (
        <>
            <Modal
                open={open}
                onCancel={handleClose}
                className='__common_model_class'
                footer={null}
                keyboard={false}
                closable={true}
                maskClosable={false}
            >
                {contextHolder}
                <ModalWrap>
                    <h4 style={{ color: '#000' }}>Import Contacts</h4>

                    <div className='mt-4 mb-4'>

                        <CommonFieldWrap>
                            <label><strong>Upload File</strong></label>
                            <Upload
                                beforeUpload={() => false}
                                onChange={handleFileChange}
                                maxCount={1}
                                fileList={fileList}
                                accept='.csv'
                            >
                                <ButtonANTD icon={<UploadOutlined />}>Upload</ButtonANTD>
                            </Upload>
                        </CommonFieldWrap>
                    </div>
                    {/* 
                    <Textarea
                        label="Notes*"
                        placeholder="Notes"
                        value={description}
                        onChange={onchangeHandler('description')}
                        height={250}
                        color="#222"
                    /> */}
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '35px' }}>
                        <Button type="primary" label='Submit' loading={loading} disabled={fileList.length === 0} onClickHandler={() => handleSave()} width='120px' />
                    </div>
                </ModalWrap>
            </Modal>
        </>
    );
};

export default ImportConnections;

ImportConnections.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
