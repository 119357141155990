// import styled from "styled-components";

// export const EventJoinWrap = styled.div`
//     width: 70%;
//     margin: 0 auto;
//     padding: 30px;
//     padding-top:0;
//     @media (max-width: 1085px) {
//         width: 100%;
//       }
//     .ant-steps{
//         position: fixed;
//         background: #fff;
//         padding: 40px 0;
//         width: calc(70% - 60px);
//         z-index: 1;
//         backdrop-filter: blur(10px);

//         @media (max-width: 585px) {
//             flex-direction:row !important;
//           }
//     }

//     .steps-content{
//         display: grid;
//     gap: 30px;
//     grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
//     width:100%;
//     }
// `

// export const CommonFieldWrap = styled.div`
//     margin-bottom:20px;
//         .__error{
//             display: block;
//             padding-top: 0;
//             padding-right: 0;
//             padding-bottom: 0;
//             padding-left: 0;
//             margin-top: 1px;
//             margin-right: 0;
//             margin-bottom: 0;
//             margin-left: 0;
//             font-size: 0.6rem;
//             color: #FF4D4F;
//         }
//         label{
//             padding-top: 0;
//             padding-right: 0;
//             padding-bottom: 0;
//             padding-left: 0;
//             margin-top: 0;
//             margin-right: 0;
//             margin-bottom: 5px;
//             margin-left: 0;
//             font-size: 0.7rem;
//             display:flex;
//             align-items:center;
//         }

//         .__link_block{
//             display:flex;
//             gap:15px;
//             align-items:flex-end;
//             button:disabled{
//                 background:#ccc !important;
//             }
//             button{
//                 height:40px;
//                 display:flex;
//                 justify-content: center;
//                 align-items:center;
//                 color:#fff;
//                 background:#666 !important;
//                 label{
//                     margin:0;
//                 }
//             }
//         }

//         .ant-checkbox-wrapper .ant-checkbox{
//             margin-top:0px;
//         }
//         .__auto_com_serch{
//             border-width: 1.5px;
//             border-radius: 6px;
//             border-color: #E1E1E1 !important;
//             font-size: 0.8rem;
//             background-color: white;
//             .ant-select-selector {
//                 border: 1px solid #d9d9d9 !important;
//             }    
//         }
// `


// export const ButtonSec = styled.div`
//     display:flex;
//     gap:15px;
//     justify-content:end;
//     align-items:center;
//     margin-top:40px;
// `


// export const JoinAs = styled.div`
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     margin-top:25px;
//     flex-direction:column;
//     gap:10px;
//     font-size:23px;
//     .ant-radio-group{
//         text-align: center;
//         label{
//             font-size:15px;
//         }
//     }

//     .ant-radio-group 
//         {
//             .ant-radio-button-wrapper {
//                 line-height: 25px;
//                 height: 32px;
//                 padding: 5px 45px;
//                 color: #666666;
//                 margin: 5px;
//                 text-align: center;
//         }
//     .ant-radio-button-wrapper-checked {
//         background-color: #ff8a00 !important;
//         color: #fff;
//     }

//     .ant-radio-button-wrapper-checked:hover{
//         color: #fff;
//     }
// }


// `

// export const TableSection = styled.div`
// padding: 20px;
// border: 1px solid #eee;
// border-radius: 20px;
// margin-top: 30px;
// width: 100%;
// overflow-x: auto;


// &::-webkit-scrollbar {
//     width: 8px;
//     border-radius: 20px;
//     height: 8px;
// }

// /* Track */
// &::-webkit-scrollbar-track {
//     background:linear-gradient(0deg, #ffffff 0%, rgb(237 237 237) 100%);
//     border-radius: 20px;
// }

// /* Handle */
// &::-webkit-scrollbar-thumb {
//     background: linear-gradient(267deg, #8b8b8b 1.18%, #e9e9e9 102.3%), #D9D9D9;
//     border-radius: 20px;
//     cursor: pointer;
// }

// /* Handle on hover */
// &::-webkit-scrollbar-thumb:hover {
//     background: #8b8b8b;
// }

// .slick-dots li button:before{
//     font-size: 12px;
// }



// `

// export const SponCardItem = styled.div`
//     border-radius: 10px;
//     background:#fdfdfd;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 10px;
//     max-width: 280px;
//     text-align: center;
//     margin:0 10px;
//     float:left;
//     border: 2px solid transparent;
//     cursor:pointer;
//     transition:0.4s;
//     .__body{
//         text-align: left;
//         max-height: 340px;
//         overflow: auto;
//         padding-right: 17px;
//         min-height: 320px;
        
//         &::-webkit-scrollbar {
//             width: 8px;
//             border-radius: 20px;
//             height: 8px;
//         }

//         /* Track */
//         &::-webkit-scrollbar-track {
//             background:linear-gradient(0deg, #ffffff 0%, rgb(237 237 237) 100%);
//             border-radius: 20px;
//         }

//         /* Handle */
//         &::-webkit-scrollbar-thumb {
//             background: linear-gradient(267deg, #8b8b8b 1.18%, #e9e9e9 102.3%), #D9D9D9;
//             border-radius: 20px;
//             cursor: pointer;
//         }

//         /* Handle on hover */
//         &::-webkit-scrollbar-thumb:hover {
//             background: #8b8b8b;
//         }
//         h5{
//             margin: 0;
//             font-size: 15px;
//             border-bottom: 1px solid #eee;
//             width: max-content;
//             font-weight: normal;
//             text-align: center;
//             color: #8d8d8d;
//         }
//         p{
//             margin: 0;
//             position: relative;
//             padding-left: 12px;
//             margin-left: 10px;
//             font-weight: 600;
//             &::before{
//                 content: "";
//                 width: 5px;
//                 height: 5px;
//                 background: #8BC34A;
//                 position: absolute;
//                 border-radius: 13px;
//                 left: 0;
//                 top: 9px;
//             }
//         }
//     }
       
//         .___head {
//             width: 100%;
//             border-bottom: 1px solid #eee;
//             margin-top: 5px;
//             padding-bottom: 5px;
//             margin-bottom: 5px;
//             display:flex;
//             flex-direction:column;
//             align-items:center;
//             span{
//                 font-size:14px;
//             }
//                 a {
//                     background: red;
//                     color: #fff;
//                     padding: 4px 20px;
//                     font-size: 16px;
//                     text-transform: uppercase;
//                     border-radius: 20px;
//                     font-weight: 500;
//                 }
//                 h2 {
//                     margin: 0;
//                     font-size: 23px;
//                 }
//             }
        
       
        

// `

// export const SelectedCard = styled.div`
// padding: 20px;
// border: 1px solid #eee;
// border-radius: 20px;
// margin-top:20px;
// display:flex;
// justify-content:space-between;
// align-items:center;
// h2{
//     margin: 0;
//     font-size: 22px;
// }
// h1{
//     margin: 0;
//     font-size: 28px;
//     color:green;
// }
// svg{
//     cursor:pointer;
//     width: 30px;
//     height: 30px;
//     fill:red;
// }
// p{
//     font-size:13px;
//     margin:0;
// }
// `

import styled from "styled-components";

export const EventJoinWrap = styled.div`
    width: 70%;
    margin: 0 auto;
    padding: 30px;
    padding-top:0;
    @media (max-width: 1085px) {
        width: 100%;
        padding:30px 10px;
      }
    .ant-steps{
        position: fixed;
        background: #fff;
        padding: 40px 0;
        width: calc(70% - 60px);
        z-index: 1;
        backdrop-filter: blur(10px);

        @media (max-width: 585px) {
            flex-direction:row !important;
          }
    }

    .steps-content{
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        width:100%;
    }

    .__forMob{
        display: flex;
        align-items: baseline;

        .__inp {
            width: 100px !important;
            margin-right: 10px;
            opacity: 1;
            height: 40px;
            pointer-events: none;
        }

        .__country {
            .__name_cls{
                padding-left: 45px;
    padding-right: 10px;
    line-height: 1;
    padding-top: 14px;
    background: #fff;
    border: 1px solid #eee;
    font-size: 12px;
    height: 40px;
    border-radius: 6px;
    margin-right: 10px;
    border-left-color: transparent;
    pointer-events: none;
            }
        }



        .react-tel-input .flag-dropdown {
            border-radius: 7px;
            height: 40px;
        }
        .react-tel-input .selected-flag {
            border-radius: 6px;
        }
    }
`

export const CommonFieldWrap = styled.div`

    

    margin-bottom:20px;
        .__error{
            display: block;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 1px;
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
            font-size: 0.6rem;
            color: #FF4D4F;
        }
        label{
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 5px;
            margin-left: 0;
            font-size: 0.7rem;
            display:flex;
            align-items:center;
        }

        .__link_block{
            display:flex;
            gap:15px;
            align-items:flex-end;
            button:disabled{
                background:#ccc !important;
            }
            button{
                height:40px;
                display:flex;
                justify-content: center;
                align-items:center;
                color:#fff;
                background:#666 !important;
                label{
                    margin:0;
                }
            }
        }

        .ant-checkbox-wrapper .ant-checkbox{
            margin-top:0px;
        }
        .__auto_com_serch{
            border-width: 1.5px;
            border-radius: 6px;
            border-color: #E1E1E1 !important;
            font-size: 0.8rem;
            background-color: white;
            .ant-select-selector {
                border: 1px solid #d9d9d9 !important;
            }    
        }
`


export const ButtonSec = styled.div`
    display:flex;
    gap:15px;
    justify-content:end;
    align-items:center;
    margin-top:40px;
`


export const JoinAs = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    gap:10px;
    margin-top:20px;
    font-size:23px;
    .ant-radio-group{
        text-align: center;
        label{
            font-size:15px;
        }
    }

    .ant-radio-group 
        {
            .ant-radio-button-wrapper {
                line-height: 25px;
                height: 32px;
                padding: 5px 45px;
                color: #666666;
                margin: 5px;
                text-align: center;
        }
    .ant-radio-button-wrapper-checked {
        background-color: #ff8a00 !important;
        color: #fff;
    }

    .ant-radio-button-wrapper-checked:hover{
        color: #fff;
    }
}


`

export const TableSection = styled.div`
padding: 20px;
border: 1px solid #eee;
border-radius: 20px;
margin-top: 30px;
width: 100%;
overflow-x: auto;


&::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
    height: 8px;
}

/* Track */
&::-webkit-scrollbar-track {
    background:linear-gradient(0deg, #ffffff 0%, rgb(237 237 237) 100%);
    border-radius: 20px;
}

/* Handle */
&::-webkit-scrollbar-thumb {
    background: linear-gradient(267deg, #8b8b8b 1.18%, #e9e9e9 102.3%), #D9D9D9;
    border-radius: 20px;
    cursor: pointer;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
    background: #8b8b8b;
}

.slick-dots li button:before{
    font-size: 12px;
}



`

export const SponCardItem = styled.div`
    border-radius: 10px;
    background:#fdfdfd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
    margin:0 10px;
    float:left;
    border: 2px solid transparent;
    cursor:pointer;
    transition:0.4s;
    width:99%;
    min-width:200px;
    .__body{
        text-align: left;
        max-height: 340px;
        overflow: auto;
        padding-right: 17px;
        min-height: 320px;
        
        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 20px;
            height: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background:linear-gradient(0deg, #ffffff 0%, rgb(237 237 237) 100%);
            border-radius: 20px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: linear-gradient(267deg, #8b8b8b 1.18%, #e9e9e9 102.3%), #D9D9D9;
            border-radius: 20px;
            cursor: pointer;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #8b8b8b;
        }
        h5{
            margin: 0;
            font-size: 15px;
            border-bottom: 1px solid #eee;
            width: max-content;
            font-weight: normal;
            text-align: center;
            color: #8d8d8d;
        }
        p{
            margin: 0;
            position: relative;
            padding-left: 12px;
            margin-left: 10px;
            font-weight: 600;
            &::before{
                content: "";
                width: 5px;
                height: 5px;
                background: #8BC34A;
                position: absolute;
                border-radius: 13px;
                left: 0;
                top: 9px;
            }
        }
    }
       
        .___head {
            width: 100%;
            border-bottom: 1px solid #eee;
            margin-top: 5px;
            padding-bottom: 5px;
            margin-bottom: 5px;
            display:flex;
            flex-direction:column;
            align-items:center;
            span{
                font-size:14px;
            }
                a {
                    background: red;
                    color: #fff;
                    padding: 4px 20px;
                    font-size: 16px;
                    text-transform: uppercase;
                    border-radius: 20px;
                    font-weight: 500;
                }
                h2 {
                    margin: 0;
                    font-size: 23px;
                }
            }
        
       
        

`

export const SelectedCard = styled.div`
padding: 20px;
border: 1px solid #eee;
border-radius: 20px;
margin-top:20px;
display:flex;
justify-content:space-between;
align-items:center;
h2{
    margin: 0;
    font-size: 22px;
}
h1{
    margin: 0;
    font-size: 28px;
    color:green;
}
svg{
    cursor:pointer;
    width: 30px;
    height: 30px;
    fill:red;
}
p{
    font-size:13px;
    margin:0;
}
`